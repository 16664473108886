import axios, { AxiosError } from "axios";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import { Slide } from "@mui/material";
import { baseUrl, errorMessage, loginErrorMessage } from "./constants";
import { toast } from "react-toastify";
import { ErrorSeverityEnum } from "./enum/error-severity.enum";
import { EventRegister } from "react-native-event-listeners";
import { EventsEnum, headerType } from "./types";

type ApiResponse = {
  data: any;
  msg: string;
  code: string;
}
export const apiDeleteRequest = async (url: string, headers?: headerType, showLoader?: boolean, data?:any): Promise<ApiResponse | null>=> {

  if(showLoader ?? true)
  EventRegister.emit(EventsEnum.Loading, true);

  let err: any;
  const response = await axios
    .delete(baseUrl.concat(url), {
      data:data,
      headers: { ...(headers?.auth == undefined ? {} : {Authorization: `Bearer ${headers?.auth}`}),'Access-Control-Allow-Origin':"*" },
    })
    .catch((error: AxiosError) => {
      err = error;
    });

  if (err) {
    toast(
      errorMessage,
      {
        type: ErrorSeverityEnum.error,
      }
    );
  if(showLoader ?? true)
    EventRegister.emit(EventsEnum.Loading, false);


    return null;
  }
  if(showLoader ?? true)
  EventRegister.emit(EventsEnum.Loading, false);
  if(response?.data.msg == "Not an authenticated user"){
    localStorage.clear();
    EventRegister.emit(EventsEnum.CheckLoginStatus, false);
    toast(
      
        loginErrorMessage,
      {
        type: ErrorSeverityEnum.error,
      }
    );
  EventRegister.emit(EventsEnum.LoginUser, false);

  }

  return response?.data;
};

export const apiGetRequest = async (url: string, headers?: headerType, showLoader?: boolean): Promise<ApiResponse | null>=> {

  if(showLoader ?? true)
  EventRegister.emit(EventsEnum.Loading, true);

  let err: any;
  const response = await axios
    .get(baseUrl.concat(url), {
      headers: { ...(headers?.auth == undefined ? {} : {Authorization: `Bearer ${headers?.auth}`}),'Access-Control-Allow-Origin':"*" },
    })
    .catch((error: AxiosError) => {
      err = error;
    });

  if (err) {
    toast(
      errorMessage,
      {
        type: ErrorSeverityEnum.error,
      }
    );
  if(showLoader ?? true)
    EventRegister.emit(EventsEnum.Loading, false);


    return null;
  }
  if(showLoader ?? true)
  EventRegister.emit(EventsEnum.Loading, false);
  if(response?.data.msg == "Not an authenticated user"){
    // localStorage.clear();
    EventRegister.emit(EventsEnum.CheckLoginStatus, false);
    toast(
      
        loginErrorMessage,
      {
        type: ErrorSeverityEnum.error,
      }
    );
  EventRegister.emit(EventsEnum.LoginUser, false);

  }

  return response?.data;
};

export const apiPostRequest = async (
  url: string,
  data: Object,
  headers?: headerType
) => {
  EventRegister.emit(EventsEnum.Loading, true);
  let err: any;
  const response: any = await axios
    .post(baseUrl.concat(url), data, {
      headers: { Authorization: `Bearer ${headers?.auth}` },
    })
    .catch((error: AxiosError) => {
      err = error;
    });

  if (err) {
    toast(
     errorMessage,
      {
        type: ErrorSeverityEnum.error,
      }
    );
    EventRegister.emit(EventsEnum.Loading, false);
    return null;
  }
  EventRegister.emit(EventsEnum.Loading, false);
  return response?.data;
};
export const apiPutRequest = async (
  url: string,
  data: Object,
  headers?: headerType
) => {
  EventRegister.emit(EventsEnum.Loading, true);
  let err: any;
  const response: any = await axios
    .put(baseUrl.concat(url), data, {
      headers: { Authorization: `Bearer ${headers?.auth}` },
    })
    .catch((error: AxiosError) => {
      err = error;
    });

  if (err) {
    toast(
      errorMessage,
      {
        type: ErrorSeverityEnum.error,
      }
    );
    EventRegister.emit(EventsEnum.Loading, false);
    return null;
  }
  EventRegister.emit(EventsEnum.Loading, false);
  return response?.data;
};

export function getValuesAsOptions<
  T extends Record<string, any>,
  K extends keyof T
>(array: T[], key: K): { label: T[K]; value: T[K] }[] {
  return array.map((obj) => ({ label: obj[key], value: obj[key] }));
}

interface Combination {
  label: string;
  value: {
    location: string;
    department: string;
    unit?: string;
  };
}

export function generateCombinations(
  objects: Array<any>,
  departmentName?: string
): Combination[] {
  const combinations: Combination[] = [];

  objects.forEach((obj) => {
    const locations = obj.location || "";
    const departments = obj.departments || [];

    departments.forEach((dept: { department: string; units: never[] }) => {
      const department = dept.department || "";
      const units = dept.units || [];

      if (!departmentName || department === departmentName) {
        if (units.length === 0) {
          const combination: Combination = {
            label: `(${locations}) ${department}`,
            value: {
              location: locations,
              department: department,
            },
          };
          combinations.push(combination);
        } else {
          units.forEach((unit: any) => {
            const combination: Combination = {
              label: `(${locations}) ${department} ${unit}`,
              value: {
                location: locations,
                department: department,
                unit: unit,
              },
            };
            combinations.push(combination);
          });
        }
      }
    });
  });

  return combinations;
}

export function convertToWordsAndHumanReadable(timestamp: string): {
  words: string;
  humanReadable: string;
} {
  const dtObject = new Date(timestamp);

  // Function to get the word representation of a number
  const numberToWords = (num: number): string => {
    const ones = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];

    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    if (num < 20) {
      return ones[num];
    } else {
      const digitOne = num % 10;
      const digitTen = Math.floor(num / 10);
      return tens[digitTen] + (digitOne ? " " + ones[digitOne] : "");
    }
  };

  const day = dtObject.getDate();
  const month = dtObject.toLocaleString("default", { month: "long" });
  const year = dtObject.getFullYear();
  const hour = dtObject.getHours();
  const minute = dtObject.getMinutes();
  const second = dtObject.getSeconds();
  const amPm = hour >= 12 ? "PM" : "AM";
  const hour12 = hour % 12 || 12;

  const words = `${numberToWords(day)} ${month} ${numberToWords(
    year
  )} ${numberToWords(hour12)} ${numberToWords(minute)} ${numberToWords(
    second
  )} ${amPm}`;
  const humanReadable = dtObject.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  });

  return { words, humanReadable };
}
