import { styled } from "@mui/material";

interface RowColumnProps {
  mainAxisAlignment?: "space-between" | "start" | "center" | "end" | "stretch";
  crossAxisAlignment?: "space-between" | "start" | "center" | "end" | "stretch";
  spacing?: number;
}

export const Row = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "mainAxisAlignment" &&
    prop !== "crossAxisAlignment" &&
    prop !== "spacing",
})<RowColumnProps>(({ mainAxisAlignment, crossAxisAlignment, spacing }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: mainAxisAlignment ?? "start",
  alignItems: crossAxisAlignment ?? "start",
  gap: spacing ? `${spacing}px` : undefined,

  "@media (max-width: 400px)": {
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

export const RowNoMinMax = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "mainAxisAlignment" &&
    prop !== "crossAxisAlignment" &&
    prop !== "spacing",
})<RowColumnProps>(({ mainAxisAlignment, crossAxisAlignment, spacing }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: mainAxisAlignment ?? "start",
  alignItems: crossAxisAlignment ?? "start",
  gap: spacing ? `${spacing}px` : undefined,
  width:"100%"
}));
export const Row2 = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "mainAxisAlignment" &&
    prop !== "crossAxisAlignment" &&
    prop !== "spacing",
})<RowColumnProps>(({ mainAxisAlignment, crossAxisAlignment, spacing }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: mainAxisAlignment ?? "start",
  alignItems: crossAxisAlignment ?? "start",
  gap: spacing ? `${spacing}px` : undefined,

  "@media (max-width: 400px)": {
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

export const FlexGrowWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "flexGrow",
})<{
  flexGrow?: number;
}>(({ flexGrow }) => ({
  flexGrow: flexGrow ?? 1,
}));

export const Column = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "mainAxisAlignment" &&
    prop !== "crossAxisAlignment" &&
    prop !== "spacing",
})<RowColumnProps>(({ mainAxisAlignment, crossAxisAlignment, spacing }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: mainAxisAlignment ?? "start",
  alignItems: crossAxisAlignment ?? "start",
  gap: spacing ? `${spacing}px` : undefined,
}));

export const FullWidthColumn = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "mainAxisAlignment" &&
    prop !== "crossAxisAlignment" &&
    prop !== "spacing",
})<RowColumnProps>(({ mainAxisAlignment, crossAxisAlignment, spacing }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  // height: "120vh",
  justifyContent: mainAxisAlignment ?? "start",
  alignItems: crossAxisAlignment ?? "start",
  gap: spacing ? `${spacing}px` : undefined,
}));

export const FullScreen = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "mainAxisAlignment" &&
    prop !== "crossAxisAlignment" &&
    prop !== "spacing",
})<RowColumnProps>(({ mainAxisAlignment, crossAxisAlignment, spacing }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: mainAxisAlignment ?? "center",
  alignItems: crossAxisAlignment ?? "center",
  gap: spacing ? `${spacing}px` : undefined,
  width: "100vw",
  height: "100vh",
}));

export const Container = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));
