import React, { useState, useMemo } from 'react';
import { 
  Autocomplete, 
  TextField, 
  Checkbox, 
  Typography, 
  ListItem, 
  ListItemIcon, 
  ListItemText,
  Collapse,
  List,
  IconButton,
  Box
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface SubCategory {
  id: number;
  sub_category_name: string;
  category_id: number;
  date_added: string;
  image_link: string | null;
  status: string;
}

interface Category {
  id: number;
  category_description: string;
  category_name: string;
  date_added: string;
  date_updated: string | null;
  image_link: string | null;
  status: string;
  sub_categories: SubCategory[];
}

interface CategoriesMultiSelectDropdownProps {
  categories: Category[];
  setCategories: (categories: SubCategory[]) => void;
}

type Option = Category | SubCategory;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CategoriesMultiSelectDropdown: React.FC<CategoriesMultiSelectDropdownProps> = ({ categories, setCategories }) => {
  const [selectedItems, setSelectedItems] = useState<SubCategory[]>([]);
  const [openCategories, setOpenCategories] = useState<number[]>([]);

  const options: Option[] = useMemo(() => categories, [categories]);

  const handleChange = (event: React.SyntheticEvent, newValue: Option[]) => {
    const newSelectedItems = newValue.filter((item): item is SubCategory => 'sub_category_name' in item);
    setSelectedItems(newSelectedItems);
    setCategories(newSelectedItems);
  };

  const toggleSubCategory = (subCategory: SubCategory) => {
    const isSelected = selectedItems.some(item => item.id === subCategory.id);
    let newSelectedItems: SubCategory[];
    if (isSelected) {
      newSelectedItems = selectedItems.filter(item => item.id !== subCategory.id);
    } else {
      newSelectedItems = [...selectedItems, subCategory];
    }
    setSelectedItems(newSelectedItems);
    setCategories(newSelectedItems);
  };

  const handleToggle = (categoryId: number, event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenCategories(prev => 
      prev.includes(categoryId)
        ? prev.filter(id => id !== categoryId)
        : [...prev, categoryId]
    );
  };

  const getOptionLabel = (option: Option) => {
    if ('category_name' in option) {
      return option.category_name;
    }
    return option.sub_category_name;
  };

  const isOptionEqualToValue = (option: Option, value: Option) => {
    if ('category_name' in option && 'category_name' in value) {
      return option.id === value.id;
    }
    if ('sub_category_name' in option && 'sub_category_name' in value) {
      return option.id === value.id;
    }
    return false;
  };

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: Option, { selected }: { selected: boolean }) => {
    if ('category_name' in option) {
      return (
        <Box key={option.id}>
          <ListItem
            {...props}
            onClick={(event) => handleToggle(option.id, event)}
            style={{ cursor: 'pointer' }}
          >
            <ListItemText 
              primary={
                <Typography style={{ fontWeight: 'bold', color: '#090A0D' }}>
                  {option.category_name}
                </Typography>
              } 
            />
            <IconButton edge="end" size="small">
              {openCategories.includes(option.id) ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItem>
          <Collapse in={openCategories.includes(option.id)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {option.sub_categories.map((subCategory) => (
                <ListItem
                  key={subCategory.id}
                  style={{ paddingLeft: 32 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleSubCategory(subCategory);
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selectedItems.some(item => item.id === subCategory.id)}
                    />
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <Typography style={{ color: '#999A9D' }}>
                        {subCategory.sub_category_name}
                      </Typography>
                    } 
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Box>
      );
    } else {
      return (
        <ListItem {...props} key={option.id} style={{ paddingLeft: 32 }}>
          <ListItemIcon>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          </ListItemIcon>
          <ListItemText 
            primary={
              <Typography style={{ color: '#194deb' }}>
                {option.sub_category_name}
              </Typography>
            } 
          />
        </ListItem>
      );
    }
  };

  return (
    <Autocomplete
      multiple
      options={options}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={renderOption}
      value={selectedItems}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Select Categories" placeholder="Search categories" />
      )}
      filterOptions={(options, state) => {
        const filtered = options.filter(option => 
          'category_name' in option 
            ? option.category_name.toLowerCase().includes(state.inputValue.toLowerCase())
            : option.sub_category_name.toLowerCase().includes(state.inputValue.toLowerCase())
        );
        return filtered.length > 0 ? filtered : options;
      }}
      sx={{
        marginTop: '16px',
        marginBottom: '10px',
        width: '100%',
        '.MuiOutlinedInput-root': { borderRadius: '100rem' },
      }}
    />
  );
};

export default CategoriesMultiSelectDropdown;