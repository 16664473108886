// export const baseUrl = 'https://api.qasa.me/v2';
// export const baseUrl = 'http://127.0.0.1:5000/v2';
export const baseUrl = process.env.REACT_APP_NODE_ENV=="development"?process.env.REACT_APP_DEV_URL??"":process.env.REACT_APP_PROD_URL??"";
export const baseReceiptUrl = process.env.REACT_APP_NODE_ENV=="development"?process.env.REACT_APP_DEV_RECEIPT_URL??"":process.env.REACT_APP_PROD_RECEIPT_URL??"";
export const urls = {
    events:"/events/",
    multipleTickets:"/payment/purchase-multiple-ticket",
    receiptDetails:"/events/receipt/",
    categories:"/events/categories",
    getCurrencies:"/currency/",
    featuredEvents:"/events/featured",
    wallet:"/wallet/",
    advertisement:"/advertisement/",
    myEventsMetrics:"/events/my-events/metrics",
    myEvents:"/events/my-events",
    purchasedTickets:"/purchased_ticket/",
    myTickets:"/events/my-tickets/metrics",
    myPurchasedTickets: "/purchased_ticket/",
    promoCodes: "/promo-codes",
    analytics: "/events/my-events/analytics",
    groupList: "/group/",
    country: "/country/",
    convertCurrency: "/currency/convert",
    cancel: "cancel/",
    promoterMessage: "promoter_message"
};

export const errorMessage = "Unable to complete request at this time";
export const loginErrorMessage ="Unable to login at this time";
export const validFieldsErrorMessage ="Ensure that all fields are filled and are all valid";
export const otpErrorMessage ="OTP does not match what we sent. Kindly check and try again";
