import React, { useEffect, useState } from "react";

import card_svg from "../_shared/assets/svg/card.svg";
import cash_svg from "../_shared/assets/svg/cash.svg";
import wallet_svg from "../_shared/assets/svg/wallet.svg";
import momo_svg from "../_shared/assets/svg/momo.svg";

import qasa_wallet from "../_shared/assets/icons/wallet-o.png";
import bank from "../_shared/assets/icons/bank.png";
import cash from "../_shared/assets/icons/cash-o.png";
import card from "../_shared/assets/icons/card-o.png";
import gpay from "../_shared/assets/icons/gpay.png";
import apple from "../_shared/assets/icons/apple.png";
import paypal from "../_shared/assets/icons/paypal.png";
import momo from "../_shared/assets/svg/momo.svg";
import { StyledTextField } from "../Auth/AuthPage";
import { Box } from "@mui/material";
import styled from "styled-components";

import { HeaderGreenTopBar } from "../_shared/styled";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { UserWallet, EventData } from "./interfaces";
import { Column, Row } from "../_shared/styledComponents";
import { urls } from "../_shared/constants";
import { apiPostRequest } from "../_shared/api";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { EventRegister } from "react-native-event-listeners";
import { EventsEnum } from "../_shared/types";
import Login from "../Auth/Login";

type Props = {
  phone: string;
  country: string;
  eventData: EventData;
  userWallet: UserWallet;
  eventPrice: number;
  callBack: any;
  checkout: boolean;
  promoCodeUsed: boolean;
};
export const CheckOutOptions = (props: Props) => {
  const [type, setType] = useState("");
  const [selectedOpt, setSelectedOpt] = useState(false);
  const [network, setNetwork] = useState("");
  const [qasaPin, setQasaPin] = useState<any>("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardCVV, setCardCVV] = useState("");
  const [userDetails, setUserDetails] = useState<any>(null);
  const [balanceAfter, setBalanceAfter] = useState<any>(0);
  const [sendEmail, setSendEmail] = useState(false);
  const [email, setEmail] = useState("");

  const handleCheckoutOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    const option = event.currentTarget.value;
    setNetwork("");
    setBalanceAfter(0);
    setType(option);
    setSelectedOpt(true);
    setBalanceAfter(0);
  };

  const getUserData = async () => {
    const storedData = localStorage.getItem("userDetails");
    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        setUserDetails(userDetailsObject);
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.LoginUser, true);
      }
    }
  };

  useEffect(() => {
    getUserData();
    if (type == "qasa_wallet") {
      convertCurrency(
        props.userWallet.wallet_currency,
        props.eventData.currency
      );
    }
  }, [type, selectedOpt]);

  const getIconSrc = (type: any) => {
    switch (type) {
      case "qasa_wallet":
        return wallet_svg;
      case "bank":
        return bank;
      case "cash":
        return cash;
      case "card":
        return card_svg;
      case "gpay":
        return gpay;
      case "apple":
        return apple;
      case "paypal":
        return paypal;
      case "momo":
        return momo;
      default:
        return "";
    }
  };

  const formatCardNumber = (value: string) => {
    // Remove all non-digit characters
    const digits = value.replace(/\D/g, "");
    // Format the digits with spaces every 4 digits
    return digits.replace(/(\d{4})(?=\d)/g, "$1 ");
  };

  const handleNetworkChange = (event: SelectChangeEvent) => {
    setNetwork(event.target.value);
  };

  // const handleExpiryDateCard = ()=> {}
  const handleExpiryChange = (e: { target: { value: any; }; }) => {
    let value = e.target.value;

    value = value.replace(/\D/g, "");

    if (value.length >= 2) {
      value = `${value.slice(0, 2)}/${value.slice(2)}`;
    }

    if (value.length > 5) {
      value = value.slice(0, 5);
    }

    setCardExpiry(value);
  };

  const convertCurrency = async (
    userCurrency: string,
    ticketCurrency: string
  ) => {
    let fromCurrency = "";
    let toCurrency = "";
    let convert = false;
    let balance = 0;

    const currencyMapping: Record<string, string> = {
      GHS: "₵",
      USD: "$",
    };

    const conversionRequired = (
      userCurrency: string,
      ticketCurrency: string
    ): boolean => {
      return (
        userCurrency !== ticketCurrency &&
        !(
          (userCurrency === "GHS" && ticketCurrency === "₵") ||
          (userCurrency === "USD" && ticketCurrency === "$")
        )
      );
    };

    if (!conversionRequired(userCurrency, ticketCurrency)) {
      const userCurrencySymbol = currencyMapping[userCurrency];
      if (userCurrencySymbol) {
        // console.log(
        //   `${userCurrency} Event, ${userCurrencySymbol}: No Conversion...`
        // );
      } else {
        // console.log(`${userCurrency} Event: No Conversion...`);
      }
    } else {
      if (userCurrency === "GHS" && ticketCurrency === "$") {
        fromCurrency = "USD";
        toCurrency = "GHS";
        // console.log("Convert dollars to cedis.");
      } else {
        fromCurrency = "GHS";
        toCurrency = "USD";
        // console.log("Convert the cedi to dollar.");
      }
      convert = true;
    }

    if (convert) {
      try {
        const {
          eventPrice,
          userWallet: { wallet_balance },
        } = props;
        const convertData = {
          from_currency: fromCurrency,
          to_currency: toCurrency,
          amount: eventPrice,
        };
        const response = await apiPostRequest(
          `${urls.convertCurrency}`,
          convertData,
          {
            auth: userDetails?.access_token,
            content_type: ContentTypeEnum.APPLICATION_JSON,
          }
        );

        if (response?.code === "00") {
          balance = wallet_balance - response?.data;
        }
      } catch (error) {
        console.error("Currency conversion failed", error);
        // Handle error appropriately
      }
    } else {
      balance = props.userWallet.wallet_balance - props.eventPrice;
    }

    setBalanceAfter(balance.toFixed(2));
  };

  return (
    <section className="checkout-section">
      {props.checkout &&
      (props.promoCodeUsed || props.eventPrice > 0) &&
      props.eventPrice > 0 ? (
        <>
          {props.userWallet && (
            <>
              <HeaderGreenTopBar />
              <div className="checkout-header">Payment Method</div>
              <div className="checkout-option-1">
                {/* Wallet */}
                {props.userWallet && (
                  <button
                    className="checkout-option-btn-new"
                    value="qasa_wallet"
                    onClick={handleCheckoutOption}
                    style={{ width: "160px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={wallet_svg}
                        alt="Wallet"
                        className="button-image"
                      />
                      <div>Wallet</div>
                    </Box>
                  </button>
                )}

                {/* Bank */}
                {/* {props.country != "Ghana" ? (
                  <button
                    className="checkout-option-btn-new"
                    value="bank"
                    onClick={handleCheckoutOption}
                  >
                    <img src={bank} alt="bank" className="button-image" />
                  </button>
                ) : (
                  ""
                )} */}

                {/* Card */}
                {
                  <button
                    className="checkout-option-btn-new"
                    value="card"
                    onClick={handleCheckoutOption}
                    style={{ width: "160px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img src={card_svg} alt="Card" className="button-image" />
                      <div>Credit/Debit</div>
                    </Box>
                  </button>
                }

                {/* Cash */}

                {/* {
                  <button
                    className="checkout-option-btn-new"
                    value="cash"
                    onClick={handleCheckoutOption}
                  >
                    <img src={cash} alt="cash" className="button-image" />
                  </button>
                } */}
              </div>
              <div className="checkout-option-2">
                {/* <button
                  className="checkout-option-btn-new"
                  value="gpay"
                  onClick={handleCheckoutOption}
                >
                  <img src={gpay} alt="gpay" className="button-image" />
                </button> */}
                {/* <button
                  className="checkout-option-btn-new"
                  value="apple"
                  onClick={handleCheckoutOption}
                >
                  <img src={apple} alt="apple" className="button-image" />
                </button> */}
                {/* <button
                  className="checkout-option-btn-new"
                  value="paypal"
                  onClick={handleCheckoutOption}
                >
                  <img src={paypal} alt="paypal" className="button-image" />
                </button> */}

                {/* Momo */}
                {props.country == "Ghana" ? (
                  <button
                    className="checkout-option-btn-new"
                    value="momo"
                    onClick={handleCheckoutOption}
                    style={{ width: "160px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={momo}
                        alt="momo"
                        className="button-image"
                        style={{
                          objectFit: "contain",
                          minWidth: "100%",
                          height: "100%",
                        }}
                      />
                    </Box>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {selectedOpt && (
            <button className="checkout-option-btn-new">
              <img src={getIconSrc(type)} className="button-image" />
            </button>
          )}
        </>
      )}
      {props.checkout &&
      (props.promoCodeUsed || props.eventPrice > 0) &&
      props.eventPrice > 0 ? (
        <>
          <br></br>
          <br></br> <br></br>
          {props.userWallet && type && <HeaderGreenTopBar />}
          {props.userWallet && type && (
            <div className="checkout-header">SELECTED METHOD: {type}</div>
          )}
          <div
            className={
              selectedOpt ? "checkout-form-display" : " checkout-form-hidden"
            }
          >
            {/* PayPal */}
            {type === "paypal" && (
              <>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={getIconSrc(type)}
                    alt="paypall"
                    className="button-image"
                  />
                  <div>PayPal</div>
                </Box>

                <input
                  type="text"
                  className="checkout-input"
                  placeholder="Paypal Email"
                />
                <br></br>
              </>
            )}

            {/* Bank */}
            {type === "bank" && (
              <>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={getIconSrc(type)}
                    alt="paypall"
                    className="button-image"
                  />
                  <div>Bank</div>
                </Box>
                <input
                  type="text"
                  className="checkout-input"
                  placeholder="Account Number"
                />
                <input
                  type="text"
                  className="checkout-input"
                  placeholder="Verify Account Number"
                />
                <input
                  type="text"
                  className="checkout-input"
                  placeholder="Routing Number"
                />
                <br></br>
              </>
            )}

            {/* Card Ghana */}
            {type === "card" && props.country === "Ghana" && (
              <>
                <Column>
                  <button
                    className="checkout-option-btn-new"
                    value="card"
                    onClick={handleCheckoutOption}
                    style={{ width: "160px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={getIconSrc(type)}
                        alt="Card"
                        className="button-image"
                      />
                      <div>Credit/Debit</div>
                    </Box>
                  </button>
                  <Row>
                    <StyledTextField
                      sx={{
                        "& fieldset": { border: "none" },
                        width: "80%",
                        maxWidth: "250px",
                        border: "1px solid lightskyblue",
                        borderRadius: "20px",
                        marginRight: "10px",
                      }}
                      InputProps={{
                        inputProps: {
                          maxLength: 16,
                          inputMode: "numeric",
                          style: {
                            textAlign: "left",
                            borderRadius: "100rem",
                            background: "rgba(255, 255, 255, 0.694)",
                            height: "20px",
                          },
                        },
                      }}
                      onChange={(e) => {
                        const input = e.target.value.replace(/\D/g, "");
                        setCardNumber(input);
                      }}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      margin="normal"
                      id="cardNumber"
                      label="Card Number"
                      name="cardNumber"
                    />

                    <StyledTextField
                      sx={{
                        "& fieldset": { border: "none" },
                        width: "80%",
                        maxWidth: "220px",
                        border: "1px solid lightskyblue",
                        borderRadius: "20px",
                        marginRight: "10px",
                      }}
                      InputProps={{
                        inputProps: {
                          inputMode: "numeric",
                          style: {
                            textAlign: "left",
                            borderRadius: "100rem",
                            background: "rgba(255, 255, 255, 0.694)",
                            height: "20px",
                          },
                          maxLength: 5,
                        },
                      }}
                      value={cardExpiry}
                      onChange={handleExpiryChange}
                      margin="normal"
                      id="cardExpiry"
                      label="Card Expiry (MM/YYYY)"
                      name="cardExpiry"
                    />

                    <StyledTextField
                      sx={{
                        "& fieldset": { border: "none" },
                        width: "80%",
                        maxWidth: "200px",
                        border: "1px solid lightskyblue",
                        borderRadius: "20px",
                        marginRight: "10px",
                      }}
                      InputProps={{
                        inputProps: {
                          maxLength: 3,
                          inputMode: "numeric",
                          style: {
                            textAlign: "left",
                            borderRadius: "100rem",
                            background: "rgba(255, 255, 255, 0.694)",
                            height: "20px",
                          },
                        },
                      }}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setCardCVV(e.target.value);
                      }}
                      margin="normal"
                      id="cvv"
                      label="CVV"
                      name="cvv"
                    />
                  </Row>
                </Column>

                <br></br>
              </>
            )}

            {/* Card US */}
            {type === "card" && props.country !== "Ghana" && (
              <>
                <Column>
                  <Row>
                    <button
                      className="checkout-option-btn-new"
                      value="card"
                      onClick={handleCheckoutOption}
                    >
                      <Row spacing={2}>
                        <img
                          src={getIconSrc(type)}
                          alt="card"
                          className="button-image"
                          style={{
                            marginRight: "10px",
                          }}
                        />
                        <div
                          style={{
                            marginTop: "5px",
                            fontFamily: "inter",
                            color: "black",
                          }}
                        >
                          Credit/Debit
                        </div>
                      </Row>
                    </button>
                    {/* <button className="checkout-option-btn-new">
                      <img src={getIconSrc(type)} className="button-image" />
                    </button> */}
                  </Row>
                </Column>

                <br></br>
              </>
            )}

            {/* Wallet */}
            {type == "qasa_wallet" && props.userWallet && (
              <>
                <Row>
                  <button
                    className="checkout-option-btn-new"
                    value="qasa_wallet"
                    style={{ width: "160px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={getIconSrc(type)}
                        alt="Wallet"
                        className="button-image"
                      />
                      <div>Wallet</div>
                    </Box>
                  </button>
                </Row>
                <Row>
                  <Column>
                    <p className="wallet-details">Current Balance</p>
                    <p className="wallet-details-sub">
                      {props.userWallet.wallet_currency}{" "}
                      {props.userWallet.wallet_balance}
                    </p>
                  </Column>

                  <Column>
                    <p className="wallet-details">Event Cost</p>
                    <p className="wallet-details-sub">
                      {props.eventData.currency}
                      {props.eventPrice.toFixed(2)}
                    </p>
                  </Column>

                  <Column>
                    <p className="wallet-details">Balance - After Purchase</p>
                    <p className="wallet-details-sub">
                      {props.userWallet.wallet_currency} {balanceAfter}{" "}
                      <Typography className="error-message">
                        {" "}
                        {balanceAfter >= 0 ? "" : "(Insufficient Balance)"}{" "}
                      </Typography>
                    </p>
                  </Column>
                </Row>
                <br></br>
                <StyledTextField
                  sx={{
                    "& fieldset": { border: "none" },
                    width: "80%",
                    maxWidth: "200px",
                  }}
                  InputProps={{
                    inputMode: "numeric",
                    inputProps: {
                      style: {
                        textAlign: "left",
                        borderRadius: "100rem",
                        background: "rgba(240, 240, 240, 1)",
                        height: "20px",
                      },
                    },
                  }}
                  onChange={(e) => {
                    setQasaPin(e.target.value);
                  }}
                  margin="normal"
                  id="qasaPin"
                  label="Qasa Pin"
                  name="qasaPin"
                />
                <br></br>
              </>
            )}

            {/* Momo */}
            {type === "momo" && (
              <>
                <Row>
                  <button
                    className="checkout-option-btn-new"
                    value="momo"
                    onClick={handleCheckoutOption}
                    style={{ width: "160px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={getIconSrc(type)}
                        alt="momo"
                        className="button-image"
                        style={{
                          objectFit: "cover",
                          minWidth: "100%",
                          height: "100%",
                        }}
                      />
                    </Box>
                  </button>
                </Row>

                <FormControl sx={{ m: 1, minWidth: 120, marginTop: 0 }}>
                  <FormHelperText>
                    Network{" "}
                    {` (0${`${userDetails?.phone ?? props.phone}`.substring(
                      3
                    )})`}
                  </FormHelperText>

                  <Select
                    value={network}
                    onChange={handleNetworkChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Select a payment" }}
                  >
                    {network === "" && (
                      <MenuItem value="" disabled>
                        Select a network
                      </MenuItem>
                    )}
                    <MenuItem value={"mtn"}>MTN</MenuItem>
                    <MenuItem value={"vodafone"}>Telecel</MenuItem>
                    <MenuItem value={"airteltigo"}>AirtelTigo</MenuItem>
                  </Select>
                </FormControl>
                <br></br>
              </>
            )}

            {/* Cash */}
            {type === "cash" && (
              <>
                <Row>
                  <button className="checkout-option-btn-new">
                    <img src={getIconSrc(type)} className="button-image" />
                  </button>
                </Row>
                <p className="wrap-text">
                  Reserve a ticket and pay at the door. Please note, your
                  reserved ticket is only valid once you pay at the door.
                </p>
              </>
            )}
          </div>
          <br></br>
          <br></br> <br></br>
        </>
      ) : (
        ""
      )}

      <HeaderGreenTopBar />
      <div className="checkout-header">
        {!props.userWallet ? "PERSONAL INFORMATION" : "CONFIRMATION"}
      </div>
      {props.userWallet && (
        <Column>
          <FormControlLabel
            control={
              <Checkbox
                checked={sendEmail}
                onChange={(event) => setSendEmail(event.target.checked)}
                color="primary"
              />
            }
            label="Send receipt to my Email"
            style={{ color: "rgba(121, 194, 216, 1)", marginTop: "1rem" }}
          />
        </Column>
      )}

      {sendEmail && (
        <Column>
          <StyledTextField
            sx={{
              "& fieldset": {
                border: " 1px solid #79C2D8",
                borderRadius: "100rem",
              },
              width: "100%",
              maxWidth: "400px",
            }}
            InputProps={{
              inputProps: {
                style: {
                  textAlign: "left",
                  borderRadius: "100rem",
                  background: "white",
                  height: "20px",
                },
              },
            }}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            margin="normal"
            id="email"
            label="Email"
            name="email"
          />
          {/* <StyledTextField
            sx={{
              "& fieldset": { border: "none" },
              width: "80%",
              maxWidth: "200px",
              border: "1px solid lightskyblue",
              borderRadius: "20px",
              marginRight: "10px",
            }}
            InputProps={{
              inputProps: {
                style: {
                  textAlign: "left",
                  borderRadius: "100rem",
                  background: "rgba(255, 255, 255, 0.694)",
                  height: "20px",
                },
              },
            }}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            margin="normal"
            id="email"
            label="Email"
            name="email"
          /> */}
        </Column>
      )}
      {!props.userWallet && (
        <div style={{ width: "100%" }}>
          <Login
            isCheckoutPage
            setOtpCode={(val) => {}}
            setViewOtp={(val: boolean) => {}}
            disableButton={!(props.checkout && balanceAfter >= 0)}
            handleSendOtp={async (
              mobile: string,
              country: string,
              firstName: string,
              lastName: string
            ) => {
              const resp = await apiPostRequest("/auth/login", {
                type: 4,
                phone: mobile,
                country: country,
                avatar:
                  "https://qasaio.nyc3.digitaloceanspaces.com/default/single_user.png",
                name: `${firstName} ${lastName}`,
                open_id: "Test1234",
              });
              // console.log(resp);
              if (resp.code == "00") {
                localStorage.setItem("userDetails", JSON.stringify(resp.data));
                EventRegister.emit(EventsEnum.CheckLoginStatus, true);
                getUserData();

                setTimeout(() => {
                  // handleCheckout();
                  props.callBack(
                    type,
                    network,
                    qasaPin,
                    cardNumber,
                    cardExpiry,
                    cardCVV,
                    sendEmail,
                    email,
                    resp.data
                  );
                }, 1000);
              }
            }}
          />
        </div>
      )}
      {props.userWallet &&
        (props.checkout && balanceAfter >= 0 ? (
          <button
            type="submit"
            className="checkout-submit-btn"
            onClick={() =>
              props.callBack(
                type,
                network,
                qasaPin,
                cardNumber,
                cardExpiry,
                cardCVV,
                sendEmail,
                email
              )
            }
          >
            Purchase
          </button>
        ) : (
          <button type="submit" disabled className="event-submit-btn-disabled">
            Purchase
          </button>
        ))}
    </section>
  );
};

const RedCheckbox = styled(Radio)(({ theme }) => ({
  marginTop: 0,
  paddingTop: 0,
  color: "#79C2D8",
  "&.Mui-checked": {
    color: "#79C2D8",
  },
}));
