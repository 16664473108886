import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { EventsEnum } from "../_shared/types";
import { EventRegister } from "react-native-event-listeners";
import { apiPostRequest } from "../_shared/api";
import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { ErrorSeverityEnum } from "../_shared/enum/error-severity.enum";
import { toast } from "react-toastify";
import { Row } from "../_shared/styledComponents";
import { ToggleSwitch } from "../_shared/components/ToggleSwitch";
import { HeaderText } from "../_shared/styled";
import { ChevronIconComponent } from "../_shared/components/ChevronIcon";
import CalendarSvg from "../_shared/assets/svg/CalendarGreen.svg";

interface Event {
  id: number;
  event_name: string;
  group_id: number;
}

interface CreateEventPopupProps {
  open: boolean;
  onClose: () => void;
  promoDetails?: any;
  handleEditStatus: any;
}

const ViewPromoCodePopup: React.FC<CreateEventPopupProps> = ({
  open,
  onClose,
  promoDetails,
  handleEditStatus
}) => {

  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>(null);

  const initFunct = async () => {
    const storedData = localStorage.getItem("userDetails");
    if (storedData && open) {
      try {
        const userDetailsObject = JSON.parse(storedData);
        setUserDetails(userDetailsObject);
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      }
    }
  };

  useEffect(() => {
    initFunct();
    setSubscribedEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          initFunct();
        }
      )
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, [open]);
  
  return (
    <StyledDialog open={open} onClose={onClose}>
      <HeaderText color="white">VIEW PROMO CODES</HeaderText>
      <Row
        spacing={10}
        crossAxisAlignment="center"
        style={{ marginTop: "1rem" }}
      >
        <ToggleSwitch checked={promoDetails.status == "Y"} handleChange={handleEditStatus} />
        <Row spacing={5}>
          <span style={{ fontWeight: "bold" }}>Status:</span>
          <span>{promoDetails.status == "Y" ? "Active" : "Inactive"}</span>
        </Row>
      </Row>

      <Grid container spacing={6} style={{width:"100%"}}>
        <Grid item xs={12} sm={6} md={6} style={{ marginTop: "16px" }}>
            <OutContainer>
                {promoDetails.code}
            </OutContainer>

        </Grid>

        <Grid item xs={12} sm={6} md={6} style={{ marginTop: "16px" }}>
        <OutContainer style={{display:"flex", justifyContent:"space-between"}}>
                <span>{promoDetails.discount_value}</span>

                <Row><ChevronIconComponent/><span>{promoDetails.discount_type == "flat_rate"?"$":"%"}</span></Row>
            </OutContainer>

        </Grid>

        <Grid item xs={12} sm={6} md={6} style={{ marginTop: "0px" }}>
        <OutContainer style={{display:"flex", justifyContent:"space-between"}}>
                <span>{promoDetails.expiry_date.split(" ").slice(0,4).join(" ")}</span>

                <img
                style={{
                  width: "18px",
                  height: "18px",
                  marginRight: "5px",
                  color:"green"
                }}
                src={CalendarSvg}
              />
            </OutContainer>

        </Grid>
       
        <Grid item xs={12} sm={6} md={6} style={{ marginTop: "0px" }}>
        <OutContainer style={{display:"flex", justifyContent:"space-between"}}>
                <span>{promoDetails.quantity}</span>

            </OutContainer>

        </Grid>
      </Grid>
    </StyledDialog>
  );
};

export default ViewPromoCodePopup;

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    background: rgba(37, 94, 135, 1);
    border-radius: 16px 0 16px 16px;
    padding: 2rem;
    color: white;
    width: 90%;
  }
`;
const OutContainer = styled('div')`
  width: 100%;
   height:60px; 
   background:white; color: rgba(175, 177, 181, 1); border-radius: 100rem; display:flex; align-items:center; padding:0 20px;margin-right:0 10px;
`;
