import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../EventDetails/css/eventDetails.css";
import styled from "styled-components";
import {
  apiDeleteRequest,
  apiGetRequest,
  apiPostRequest,
  apiPutRequest,
} from "../_shared/api";
import {
  BodyText,
  HeaderGreenTopBar,
  HeaderOption,
  HeaderText,
} from "../_shared/styled";
import AddSvg from "../_shared/assets/svg/add.svg";
import TrashSvg from "../_shared/assets/svg/trash.svg";
import PencilSvg from "../_shared/assets/svg/pencil.svg";
import SendSvg from "../_shared/assets/svg/send.svg";

import { ContentTypeEnum } from "../_shared/enum/content-type.enum";
import { loadStripe } from "@stripe/stripe-js";
import {
  baseReceiptUrl,
  baseUrl,
  errorMessage,
  urls,
} from "../_shared/constants";
import VaryingWidthImage from "../_shared/components/imageViewer";
import { EventRegister } from "react-native-event-listeners";
import { CountryCodes, EventsEnum } from "../_shared/types";
import { toast } from "react-toastify";
import {
  Container,
  Dialog,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  Switch,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StyledTextField } from "../Auth/AuthPage";
import {
  Box,
  Typography,
  Button,
  Modal,
  SelectChangeEvent,
} from "@mui/material";
import { Column, FullWidthColumn, Row } from "../_shared/styledComponents";
import { ErrorSeverityEnum } from "../_shared/enum/error-severity.enum";
import {
  TicketInfo,
  UserWallet,
  Ticket,
  EventData,
  CustomFields,
  Announcement,
} from "../EventDetails/interfaces";
import { Elements } from "@stripe/react-stripe-js";
import { Helmet } from "react-helmet";
import { DisplayStatusEnum } from "../Home/_helpers/types";
import { formatPhoneNumber, stripPhoneNumber } from "../_shared/functions";
import Popup from "../_shared/components/alert";
import CustomTextField from "../_shared/components/CustomtextField";
import InputField from "../_shared/components/InputField";
import ConfirmationModal from "../_shared/components/ConfirmationModal";
import { ToggleSwitch } from "../_shared/components/ToggleSwitch";
import CreatePromoCodePopup from "../Event_Admin/addPromoCode";
import Bin from "../_shared/assets/svg/Bin.svg";
import { Visibility, VisibilityOutlined } from "@mui/icons-material";
import { calc } from "antd/es/theme/internal";
import { ToggleSwitchWhiteKnob } from "../_shared/components/ToggleSwitchWhiteKnow";
import ViewPromoCodePopup from "./PreviewTicketInfo";
// import CreatePromoCodePopup from "../Event_Admin/addPromoCode";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

interface AnnouncementData {
  event_id: number | undefined;
  group_id: number | undefined;
  msg: string;
  subject?: string;
  send_sms?: number;
  send_email?: number;
}

function EventInformation() {
  const [eventDetails, setEventDetails] = useState<EventData>();
  const [eventTickets, setEventTickets] = useState<Array<Ticket>>([]);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [selectedPromoCode, setSelectedPromoCode] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [qasaPin, setQasaPin] = useState<any>("");
  const [paymentMode, setPaymentMode] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [eventPoster, setEventPoster] = useState("");
  const [email, setEmail] = useState("");
  const [network, setNetwork] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState("");
  const [promoCodeUsed, setPromoCodeUsed] = useState(false);
  const [value, setValue] = useState<number>(1);
  const [retry_count, setRetryCount] = useState<number>(1);
  const [showPaymentStripe, setShowStripePayment] = useState(false);
  const [showPaymentHubtel, setShowHubtelPayment] = useState(false);
  const [stripeElementLoaded, setStripeElementLoaded] = useState(false);
  const [stripeOptions, setStripeOptions] = useState<any>({});
  const [adData, setAdData] = useState<any>(null);
  const [subscribedEvent, setSubscribedEvent] = useState<any>();
  const [hubtelUrlToRender, setHubtelUrlToRender] = useState<any>();
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(false);
  const [ticketSelected, setTicketSelected] = useState(false);
  const [groupAdmins, setGroupAdmins] = useState<Array<any>>([]);
  const [newGroupAdmins, setNewGroupAdmins] = useState<
    Array<{ phone: any; countryCode: any; staffName: any }>
  >([]);

  const stripePromise = loadStripe(
    process.env.REACT_APP_NODE_ENV == "development"
      ? process.env.REACT_APP_STRIPE_DEV_KEY ?? ""
      : process.env.REACT_APP_STRIPE_PROD_KEY ?? ""
  );

  const [end_date, setEndDate] = useState("Thu, 23 May 2024 18:30:00 GMT");
  const [event_date, setEventDate] = useState("Thu, 23 May 2024 18:30:00 GMT");
  const [event_time, setEventTime] = useState("16:30:00 GMT");
  const [localEndDate, setLocalEndDate] = useState("");
  const [localEventDate, setLocalEventDate] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [checkout, setCheckout] = useState(false);
  const [ticketCounts, setTicketCounts] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showPopUp1, setShowPopUp1] = useState(false);
  const [showPopUp2, setShowPopUp2] = useState(false);
  const [ticketList, setTicketList] = useState(eventTickets?.map(() => 0));
  const [subTotal, setSubTotal] = useState(0);
  const [currency, setCurrency] = useState("");
  const [ticketData, setTicketData] = useState<TicketInfo[]>([]);
  const [customFields, setCustomFields] = useState<CustomFields[]>([]);
  const [customFieldValues, setFieldValues] = useState(
    customFields?.map((field) => ({ id: field.id, value: "" }))
  );

  const [countryCode, setCountryCode] = useState<any>(""); // State to hold the selected country code
  const [phoneNumber, setPhoneNumber] = useState<string>(""); // State to hold the selected country code
  const [staffName, setStaffName] = useState<string>(""); // State to hold the selected country code
  const [buttonText, setText] = useState<string>("LOGIN");
  const [isPasting, setIsPasting] = useState<boolean>(false); // Flag to indicate paste event
  const [country, setCountry] = useState<CountryCodes>({}); // Flag to indicate paste event
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [addAnnouncement, setAddAnnouncement] = useState(false);
  const [showAddPromoCodes, setShowAddPromoCodes] = useState(false);
  const [sendSMS, setSendSMS] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [announcementSubject, setAnnouncementSubject] = useState("");
  const [announcementMessage, setAnnouncementMessage] = useState("");
  const [eventMetrics, setEventMetrics] = useState<any>();
  const [rows, setRows] = useState([]);
  const [promoCodeData, setPromoCodeData] = useState([]);
  const [addPromoCode, setAddPromoCode] = useState<any>(false);
  const [switchchecked, setswitchchecked] = useState(false);
  const [cancelAction, setCancelAction] = useState(false);
  const [displayStatus, setDisplayStatus] = useState("");
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setswitchchecked(event.target.checked);
  };
  // console.log(eventMetrics, "eventMetrics");

  useEffect(() => {
    initFunction();
    const getTimezoneFromAPI = async () => {
      try {
        // Fetch user's timezone from WorldTime API
        const response = await fetch("https://worldtimeapi.org/api/ip");
        const data = await response.json();
        return data.timezone;
      } catch (error) {
        console.error("Error fetching timezone:", error);
        // Default timezone in case of error
        return "UTC";
      }
    };

    const convertToLocalDateTime = async () => {
      try {
        const tz = await getTimezoneFromAPI();

        const endDateTime = new Date(end_date);
        const eventDateTime = new Date(event_date);

        // Convert UTC to local date and time
        const localEndDate = endDateTime.toLocaleString("en-US", {
          timeZone: tz,
        });
        const localEventDate = eventDateTime.toLocaleString("en-US", {
          timeZone: tz,
        });

        setLocalEndDate(localEndDate);
        setLocalEventDate(localEventDate);
        setTimeZone(tz);
      } catch (error) {
        console.error("Error converting to local date time:", error);
      }
    };

    convertToLocalDateTime();
  }, [end_date, event_date]);

  const getAd = async () => {
    const adRes = await apiGetRequest(`${urls.advertisement}`, {
      auth: undefined,
      content_type: ContentTypeEnum.APPLICATION_JSON,
    });

    if (adRes?.code === "00") {
      setAdData(adRes.data);
    }
  };

  function convertDateFormat(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const handleConfirm = async () => {
    setCancelAction(true);
  };

  const handleConfirm2 = async () => {
    setCancelAction(true);
  };

  // useEffect(() => {
  //   if (cancelAction) {
  //     handleSendAnnouncement1();
  //   }
  // }, [cancelAction])

  const handleDeleteEvent = async () => {
    // console.log("Delete Event");

    const delRes = await apiDeleteRequest(`${urls.events}${eventDetails?.id}`, {
      auth: userDetails?.access_token,
      content_type: ContentTypeEnum.APPLICATION_JSON,
    });
    // console.log(delRes);
    if (delRes?.code === "00") {
      navigate("/event-admin");
    } else {
      toast(delRes?.msg, {
        type: ErrorSeverityEnum.error,
      });
    }
  };

  const handleUnpublish = async () => {
    const eventFormData = new FormData();
    eventFormData.append("event_name", eventDetails?.event_name ?? "");
    eventFormData.append(
      "event_date",
      convertDateFormat(eventDetails?.event_date ?? "")
    );
    eventFormData.append(
      "end_date",
      convertDateFormat(eventDetails?.event_date ?? "")
    );
    eventFormData.append("event_description", eventDetails?.description ?? "");
    eventFormData.append("venue", eventDetails?.venue ?? "");
    eventFormData.append("visibility", eventDetails?.visibility ?? "");
    eventFormData.append("display_status", "draft");

    if (eventDetails?.id) {
      eventFormData.append(
        "event_show_date",
        convertDateFormat(eventDetails?.event_date ?? "")
      );

      const res = await apiPutRequest(
        `${urls.events}update/${eventDetails?.id}`,
        eventFormData,
        {
          auth: userDetails.access_token,
          content_type: ContentTypeEnum.MULTIPART_FORM,
        }
      );
      // console.log("API Response:", res);
      if (res?.code == "00") {
        toast("Your event has been unpublished", {
          type: ErrorSeverityEnum.success,
        });
        navigate("/event-admin");
      } else {
        toast(errorMessage, {
          type: ErrorSeverityEnum.error,
        });
      }
    }
    // else {
    //   // If it's a new event, create it
    //   const res = await apiPostRequest(
    //     `${urls.events}add`,
    //     eventFormData,
    //     {
    //       auth: userDetails.access_token,
    //       content_type: ContentTypeEnum.MULTIPART_FORM,
    //     }
    //   );
    //   if (res?.code == "00") {
    //     toast(res.msg ?? "New Event Saved as Draft", {
    //       type: ErrorSeverityEnum.success,
    //     });
    //     navigate("/event-admin");
    //   } else {
    //     toast(res.msg ?? "Failed to Save New Draft", {
    //       type: ErrorSeverityEnum.error,
    //     });
    //   }
    // }
  };

  const handleCancel = () => {
    setShowPopUp(false);
  };
  const handleCancel1 = () => {
    setShowPopUp1(false);
  };

  const handleCancel2 = () => {
    setShowPopUp2(false);
  };

  const handlePublish = async () => {
    const eventFormData = new FormData();
    eventFormData.append("event_name", eventDetails?.event_name ?? "");
    eventFormData.append(
      "event_date",
      convertDateFormat(eventDetails?.event_date ?? "")
    );
    eventFormData.append(
      "end_date",
      convertDateFormat(eventDetails?.event_date ?? "")
    );
    eventFormData.append("event_description", eventDetails?.description ?? "");
    eventFormData.append("venue", eventDetails?.venue ?? "");
    eventFormData.append("visibility", eventDetails?.visibility ?? "");
    eventFormData.append("display_status", "published");

    if (eventDetails?.id) {
      if (
        eventDetails?.display_status == DisplayStatusEnum.draft ||
        !eventDetails?.group_id
      ) {
        const createGroupRes = await apiPostRequest(
          "/group/add",
          {
            group_name: eventDetails?.event_name ?? "",
            description: `This is the group for the ${
              eventDetails?.event_name ?? ""
            } event`,
            visibility: eventDetails?.visibility ?? "",
          },
          {
            auth: userDetails.access_token,
            content_type: ContentTypeEnum.APPLICATION_JSON,
          }
        );
        if (createGroupRes?.code === "00") {
          eventFormData.append("group_id", createGroupRes.data.id.toString());
        } else {
          toast(errorMessage, {
            type: ErrorSeverityEnum.error,
          });
          return;
        }
      }
      eventFormData.append(
        "event_show_date",
        convertDateFormat(eventDetails?.event_date ?? "")
      );

      const res = await apiPutRequest(
        `${urls.events}update/${eventDetails?.id}`,
        eventFormData,
        {
          auth: userDetails.access_token,
          content_type: ContentTypeEnum.MULTIPART_FORM,
        }
      );
      if (res?.code == "00") {
        toast("Your event has been published", {
          type: ErrorSeverityEnum.success,
        });
        navigate("/event-admin");
      } else {
        toast(errorMessage, {
          type: ErrorSeverityEnum.error,
        });
      }
    }
  };

  const getUserData = async () => {
    const storedData = localStorage.getItem("userDetails");
    if (storedData) {
      try {
        const userDetailsObject = JSON.parse(storedData);

        setUserDetails(userDetailsObject);
      } catch (error) {
        localStorage.clear();
        EventRegister.emit(EventsEnum.LoginUser, true);
      }
    }
  };

  const initFunction = async () => {
    const pathname = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);

    const adRes = await apiGetRequest(`${urls.country}`, {
      auth: undefined,
      content_type: ContentTypeEnum.APPLICATION_JSON,
    });

    if (adRes?.code === "00") {
      const countries: CountryCodes = {};
      [...adRes.data].forEach((element: any) => {
        countries[`${element.country_code}`] = {
          code: `${element.dialing_prefix}`.substring(1),
          name: `${element.country_name_in_english}`,
        };
      });
      setCountry(countries);
    }

    if (pathname.startsWith("/ea/")) {
      const purl = pathname.split("/")[2];
      const res = await apiGetRequest(`${urls.events}${purl}`);
      if (res?.data != null) {
        setEventDetails(res.data);
        setEventPoster(res.data.poster);
        setEventTickets(res.data.tickets ?? []);
        setEventDate(res.data.event_date);
        setEndDate(res.data.end_date);
        setEventTime(res.data.end_date);
        setCurrency(res.data.currency);
        setTicketList(eventTickets?.map(() => 0));
        setCustomFields(res.data.custom_fields);
        setDisplayStatus(res.data.display_status);

        if ((res.data.tickets ?? []).length > 0) {
          setSelectedTicket(res.data.tickets[0].id);
        }
      }
    } else if (searchParams.has("eventId")) {
      const eventId = searchParams.get("eventId");
      const res = await apiGetRequest(`${urls.events}${eventId}`);
      if (res?.data != null) {
        setEventDetails(res.data);
        setEventPoster(res.data.poster);
        setEventTickets(res.data.tickets ?? []);
        setEventDate(res.data.event_date);
        setEndDate(res.data.end_date);
        setEventTime(res.data.end_date);
        setCurrency(res.data.currency);
        setTicketList(eventTickets?.map(() => 0));
        setCustomFields(res.data.custom_fields);

        if ((res.data.tickets ?? []).length > 0) {
          setSelectedTicket(res.data.tickets[0].id);
        }
      }
    }

    if (displayStatus !== "cancelled") {
      const storedData = localStorage.getItem("userDetails");
      if (storedData) {
        try {
          const userDetailsObject = JSON.parse(storedData);

          const res = await apiGetRequest(
            `${urls.groupList}${eventDetails?.group_id}`,
            {
              auth: userDetailsObject?.access_token,
              content_type: ContentTypeEnum.APPLICATION_JSON,
            }
          );
          if (res?.code == "00") {
            setGroupAdmins(res?.data?.group_members ?? []);
          }

          const announcementResponse = await apiGetRequest(
            `${urls.events}${urls.promoterMessage}?event_id=${eventDetails?.id}`,
            {
              auth: userDetailsObject?.access_token,
              content_type: ContentTypeEnum.APPLICATION_JSON,
            }
          );
          if (announcementResponse?.code == "00") {
            setAnnouncements(announcementResponse?.data ?? []);
          }

          // Promo code data extraction
          const promoCodeRes = await apiGetRequest(`${urls.myEvents}`, {
            auth: userDetailsObject?.access_token,
            content_type: ContentTypeEnum.APPLICATION_JSON,
          });

          if (promoCodeRes?.code === "00") {
            let globalIdCounter = 1;
            const rows = promoCodeRes.data.flatMap((event: any) =>
              event.promo_codes?.map((promo_code: any) => ({
                id: globalIdCounter++,
                promo_event_name: event.event_name,
                promo_code_name: promo_code.code,
                quantity: promo_code.quantity,
                discount_type: promo_code.discount_type,
                discount_value: promo_code.discount_value,
              }))
            );
            setRows(rows);

            const promoCodeData = promoCodeRes.data.flatMap(
              (event: any) => event.promo_codes
            );
            setPromoCodeData(promoCodeData);
          }

          const eventMetricsRes = await apiGetRequest(
            `/ticket/my-event-tickets/analytics/${eventDetails?.id}`,
            {
              auth: userDetailsObject?.access_token,
              content_type: ContentTypeEnum.APPLICATION_JSON,
            }
          );

          if (eventMetricsRes?.code === "00") {
            setEventMetrics(eventMetricsRes.data);
          }
        } catch (error) {}
      }
    }

    if (searchParams.has("promCode")) {
      const promCode = searchParams.get("promCode");
      setPromoCode(promCode ?? "");
    }
  };

  const allAnnouncementValuesFilled = () => {
    if (sendEmail) {
      if (!announcementSubject || !announcementMessage) {
        return false;
      }
    } else if (sendSMS) {
      if (!announcementMessage) {
        return false;
      }
    }
    return true;
  };

  const discardAnnouncement = async () => {
    setAddAnnouncement(false);
    setCancelAction(false);
  };

  const handleSendAnnouncement1 = async () => {
    if (!allAnnouncementValuesFilled()) {
      toast("Please fill in all fields before saving.", { type: "error" });
      return;
    }

    const announcementData: AnnouncementData = {
      event_id: eventDetails?.id,
      group_id: eventDetails?.group_id,
      msg: announcementMessage,
    };

    if (sendEmail) {
      announcementData.send_email = 1;
      announcementData.subject = announcementSubject;
    } else if (sendSMS) {
      announcementData.send_sms = 1;
    }

    const sendAnnouncementResp = await apiPostRequest(
      `${urls.events}${urls.promoterMessage}`,
      announcementData,
      {
        auth: userDetails.access_token,
        content_type: ContentTypeEnum.APPLICATION_JSON,
      }
    );

    if (sendAnnouncementResp.code == "00") {
      toast(sendAnnouncementResp.msg ?? "Announcements Sent Succesfully.", {
        type: ErrorSeverityEnum.success,
      });
      // window.location.reload();
    } else {
      toast(errorMessage, {
        type: ErrorSeverityEnum.error,
      });
    }

    try {
      const res = await apiPostRequest(
        `${urls.events}${urls.cancel}${eventDetails?.id}`,
        {},
        {
          auth: userDetails.access_token,
          content_type: ContentTypeEnum.APPLICATION_JSON,
        }
      );

      if (res?.code === "00") {
        toast(res.msg ?? "Your event has been cancelled", {
          type: ErrorSeverityEnum.success,
        });
        // Optionally navigate to the event admin page
        navigate("/event-admin");
      } else {
        throw new Error(res?.msg || "Failed to cancel the event");
      }
    } catch (error) {
      console.error("Error in handleConfirm:", error);
      toast(
        (error as Error).message || "Unable to complete request at this time",
        {
          type: ErrorSeverityEnum.error,
        }
      );
    } finally {
      setAddAnnouncement(false);
      setSendSMS(false);
    }
  };
  let handleSendAnnouncement = async () => {
    if (!allAnnouncementValuesFilled()) {
      toast("Please fill in all fields before saving.", { type: "error" });
      return;
    }

    const announcementData: AnnouncementData = {
      event_id: eventDetails?.id,
      group_id: eventDetails?.group_id,
      msg: announcementMessage,
    };

    if (sendEmail) {
      announcementData.send_email = 1;
      announcementData.subject = announcementSubject;
    } else if (sendSMS) {
      announcementData.send_sms = 1;
    }

    const sendAnnouncementResp = await apiPostRequest(
      `${urls.events}${urls.promoterMessage}`,
      announcementData,
      {
        auth: userDetails.access_token,
        content_type: ContentTypeEnum.APPLICATION_JSON,
      }
    );

    if (sendAnnouncementResp.code == "00") {
      toast(sendAnnouncementResp.msg ?? "Announcements Sent Succesfully.", {
        type: ErrorSeverityEnum.success,
      });
      // window.location.reload();
    } else {
      toast(errorMessage, {
        type: ErrorSeverityEnum.error,
      });
    }
  };

  const allValuesFilled = () => {
    for (const newGroupAdmin of newGroupAdmins) {
      if (
        !newGroupAdmin.staffName ||
        !newGroupAdmin.phone ||
        !newGroupAdmin.countryCode
      ) {
        return false;
      }
    }
    return true;
  };
  useEffect(() => {
    initFunction();
    getAd();

    getUserData();

    setSubscribedEvent(
      EventRegister.addEventListener(
        EventsEnum.CheckLoginStatus,
        (data: boolean) => {
          getAd();
        }
      )
    );
    return () => {
      EventRegister.removeEventListener(subscribedEvent);
    };
  }, []);

  const resetForm = () => {
    setOpen(false);
    setUserDetails(null);
    setQasaPin("");
    setPaymentMode("");
    setValue(1);
    setRetryCount(1);
    setStripeElementLoaded(false);
    setShowStripePayment(false);
    setPromoCodeUsed(false);
    setChecked(false);
    setDiscountedAmount("");
    setPromoCode("");
    setEmail("");
    setCheckout(false);
  };

  const handlePaymentModeChange = (event: SelectChangeEvent) => {
    setPaymentMode(event.target.value);
  };
  const handleNetworkChange = (event: SelectChangeEvent) => {
    setNetwork(event.target.value);
  };

  const handleNumberInputChange = (val: number) => {
    setValue(val);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCancelAction(false);
  };

  const sanitizeTimezone = (timezone: string): string => {
    return timezone.replace(",", "/").replace(" ", "");
  };

  const extractTimeFromDate = (dateStr: string | number | Date) => {
    if (!eventDetails?.event_timezone) {
      return "";
    }

    const sanitizedTimezone = sanitizeTimezone(eventDetails.event_timezone);
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: sanitizedTimezone,
      timeZoneName: "short",
    };

    try {
      const formatter = new Intl.DateTimeFormat("en-US", options);
      const formattedDateParts = formatter.formatToParts(date);

      let timeString = "";
      let timeZone = "";

      formattedDateParts.forEach(({ type, value }) => {
        if (type === "hour" || type === "minute" || type === "second") {
          timeString += value.padStart(2, "0") + ":";
        }
        if (type === "timeZoneName") {
          timeZone = value;
        }
      });

      timeString = timeString.slice(0, -1);

      return `${timeString} (${timeZone})`;
    } catch (error) {
      console.error("Invalid timezone:", sanitizedTimezone);
      return "Invalid timezone";
    }
  };

  function capitalizeText(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  const formatDate = (dateString: string): string => {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Define an array of month names
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the date components
    const month = months[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    // Format the date string
    return `${month} ${day} ${year}`;
  };

  // Function to handle pasted input
  const handlePaste = (e: any, index: number): void => {
    setIsPasting(true); // Set the flag to true for paste event
    const pastedText: string = e.clipboardData.getData("text");
    const strippedNumber: string = stripPhoneNumber(pastedText, true);
    const updatedNewGroupAdmins = newGroupAdmins?.map((nga, ind) => {
      if (ind === index) {
        return { ...nga, phone: strippedNumber };
      }
      return nga;
    });
    setNewGroupAdmins(updatedNewGroupAdmins);
  };

  // Function to handle typed input
  const handleChange = (e: any, index: number): void => {
    if (!isPasting) {
      const typedNumber: string = e.target.value;
      const strippedNumber: string = stripPhoneNumber(typedNumber, false);
      const updatedNewGroupAdmins = newGroupAdmins?.map((nga, ind) => {
        if (ind === index) {
          return { ...nga, phone: strippedNumber };
        }
        return nga;
      });
      setNewGroupAdmins(updatedNewGroupAdmins);
    }
    setIsPasting(false); // Reset the flag after handling the input
  };
  const handleCountryCodeChange = (event: any, index: number) => {
    // setCountryCode(event.target.value);
    const updatedNewGroupAdmins = newGroupAdmins?.map((nga, ind) => {
      if (ind === index) {
        return { ...nga, countryCode: event.target.value };
      }
      return nga;
    });
    setNewGroupAdmins(updatedNewGroupAdmins);
  };

  const handleAddNewStaff = async () => {
    const initialLength = newGroupAdmins.length;
    for (let index = 0; index < newGroupAdmins.length; index++) {
      const groupAdmin = newGroupAdmins[index];
      const parts = groupAdmin.countryCode.split(" ", 2);
      const code = parts[0];
      const selectedCountry = groupAdmin.countryCode.substring(
        parts[0].length + 1
      );
      const internationalNumber = `${code}${
        groupAdmin.phone[0] == "0"
          ? groupAdmin.phone.substring(1)
          : groupAdmin.phone
      }`;

      const resp = await apiPostRequest("/auth/login", {
        type: 4,
        phone: internationalNumber,
        country: selectedCountry,
        avatar:
          "https://qasaio.nyc3.digitaloceanspaces.com/default/single_user.png",
        name: `${groupAdmin.staffName}`,
        open_id: "Test1234",
      });

      if (resp.code == "00") {
        const addToGroupRes = await apiPostRequest(
          "/group-member/add",
          { user_id: resp?.data.id, group_id: eventDetails?.group_id },
          {
            auth: userDetails?.access_token,
            content_type: ContentTypeEnum.APPLICATION_JSON,
          }
        );

        if (addToGroupRes?.code == "00" || addToGroupRes?.code == "01") {
          const member_id =
            addToGroupRes?.code == "01"
              ? groupAdmins.find(
                  (admin) =>
                    admin.phone ===
                    `${groupAdmin.countryCode.split(" ", 2)[0]}${
                      groupAdmin.phone[0] == "0"
                        ? groupAdmin.phone.substring(1)
                        : groupAdmin.phone
                    }`
                ).id
              : addToGroupRes?.data?.id;

          const makeGroupAdminRes = await apiPutRequest(
            "/group-member/make-admin",
            {
              member_id: member_id,
              group_id: eventDetails?.group_id,
            },
            {
              auth: userDetails?.access_token,
              content_type: ContentTypeEnum.APPLICATION_JSON,
            }
          );

          if (makeGroupAdminRes.code == "00") {
            const filteredNewAdmins = newGroupAdmins.filter(
              (x, ind) => index !== ind
            );
            setNewGroupAdmins(filteredNewAdmins);
          }
        }
      }
    }

    toast(`Staff added successfully`, {
      type: ErrorSeverityEnum.success,
    });
    const res = await apiGetRequest(
      `${urls.groupList}${eventDetails?.group_id}`,
      {
        auth: userDetails?.access_token,
        content_type: ContentTypeEnum.APPLICATION_JSON,
      }
    );
    if (res?.code == "00") {
      setGroupAdmins(res?.data?.group_members ?? []);
      setNewGroupAdmins([]);
    }
  };

  const textAreaStyle = {
    flex: 1,
    border: "none",
    resize: "none",
    outline: "none",
    fontSize: "16px",
  };

  const formattedDate = eventDetails?.event_date
    ? new Intl.DateTimeFormat("en-US", {
        weekday: "short",
        day: "2-digit",
        month: "short",
        year: "numeric",
      }).format(new Date(eventDetails.event_date))
    : "Date not available";

  return (
    <>
      <>
        {eventDetails && (
          <CreatePromoCodePopup
            open={addPromoCode}
            onClose={() => {
              setAddPromoCode(false);
            }}
            eventDetails={eventDetails}
          />
        )}
        {(showPopUp || showPopUp1) && (
          <ConfirmationModal
            open={showPopUp || showPopUp1}
            question={
              showPopUp1
                ? "Are you sure you want to cancel Event?"
                : "Are you sure you want to Unpublish Event?"
            }
            onClose={showPopUp1 ? handleCancel1 : handleCancel}
            onConfirm={showPopUp1 ? handleConfirm : handleUnpublish}
          />
        )}

        {showPopUp2 && (
          <ConfirmationModal
            open={showPopUp2}
            question={"Are you sure you want to Delete Event?"}
            onClose={handleCancel2}
            onConfirm={handleDeleteEvent}
          />
        )}
        <CreatePromoCodePopup
          open={showAddPromoCodes}
          onClose={() => {
            setShowAddPromoCodes(false);
          }}
          eventDetails={eventDetails}
        />

        {/* <StyledDialog
          open={showAddPromoCodes}
          onClose={() => setShowAddPromoCodes(false)}
        >
          <HeaderText color="white">PROMO CODES</HeaderText>
          <Row
            spacing={10}
            crossAxisAlignment="center"
            style={{ marginTop: "1rem" }}
          >
            <ToggleSwitch />
            <Row spacing={5}>
              <span style={{ fontWeight: "bold" }}>Status:</span>
              <span>Inactive</span>
            </Row>
          </Row>

          <Grid container spacing={3}>
            <Grid
              item
              xs={12} sm={6} md={6}
              style={{ marginTop: "16px" }}
            >
               <StyledTextField
              variant="outlined"
              sx={{
                "& fieldset": {
                  border: "1px solid #79C2D8",
                  borderRadius: "1000px",
                  margin: 0,
                },
                width: "100%",
                // maxWidth: "600px",
                margin: 0,
              }}
              InputProps={{
                inputProps: {
                  style: {
                    textAlign: "left",
                    borderRadius: "100rem",
                    height: "20px",
                    background: "white",
                  },
                },
              }}
              onChange={(e) => setAnnouncementSubject(e.target.value)}
              margin="normal"
              id="code_name"
              label="Code Name"
              name="code_name"
            />

            </Grid>

            <Grid item xs={12} sm={6} md={6}
            style={{ marginTop: "16px" }}
            >
            <StyledTextField
    select
    variant="outlined"
    sx={{
      "& fieldset": {
        border: "1px solid #79C2D8",
        borderRadius: "1000px",
        margin: 0,
      },
      width: "100%",
      margin: 0,
      borderRadius: "1000px",
      background:"white"


    }}
    InputProps={{
      inputProps: {
        style: {
          textAlign: "left",
          borderRadius: "100rem",
          height: "20px",
          background: "white",

        },
      },
    }}
    onChange={(e) => setAnnouncementSubject(e.target.value)}
    margin="normal"
    id="discount"
    label="Discount"
    name="discount"
  >
    <MenuItem value="%"> % - Percentage </MenuItem>
    <MenuItem value="$"> $ - Amount </MenuItem>
  </StyledTextField>
            </Grid>
          </Grid>

          <Row
            crossAxisAlignment="center"
            spacing={50}
            style={{ marginTop: "5rem" }}
          >
            <Button
              variant="contained"
              sx={{
                width: "550px",
                height: "50px",
                borderRadius: "100rem",
                backgroundColor: "white",
                border: "2px solid #0CAF6D",
              }}
              onClick={discardAnnouncement}
            >
              <Typography
                style={{
                  color: "#0CAF6D",
                }}
              >
                Discard
              </Typography>
            </Button>

            <Button
              variant="contained"
              sx={{
                width: "550px",
                height: "50px",
                borderRadius: "100rem",
                backgroundColor: "white",
                border: "2px solid #0CAF6D",
              }}
            >
              <Typography
                style={{
                  color: "#0CAF6D",
                }}
              >
                Save Draft
              </Typography>
            </Button>

            <Button
              variant="contained"
              sx={{
                width: "550px",
                height: "50px",
                borderRadius: "100rem",
                backgroundColor: "#0CAF6D",
                border: "2px solid #0CAF6D",
              }}
              onClick={handleSendAnnouncement}
            >
              Send
            </Button>
          </Row>
        </StyledDialog> */}
        <StyledDialog
          open={addAnnouncement || cancelAction}
          onClose={handleClose}
        >
          <HeaderText color="white">EVENT ANNOUNCEMENT</HeaderText>
          <Row spacing={20} style={{ marginTop: "1rem" }}>
            <FormControlLabel
              control={
                <RedCheckbox
                  checked={sendSMS}
                  onChange={(event) => {
                    setSendEmail(false);
                    setSendSMS(event.target.checked);
                  }}
                />
              }
              label="SMS"
              style={{ color: "rgba(121, 194, 216, 1)", marginTop: "1rem" }}
            />

            <FormControlLabel
              control={
                <RedCheckbox
                  checked={sendEmail}
                  onChange={(event) => {
                    setSendSMS(false);
                    setSendEmail(event.target.checked);
                  }}
                />
              }
              label="EMAIL"
              style={{ color: "rgba(121, 194, 216, 1)", marginTop: "1rem" }}
            />
          </Row>

          {sendEmail && (
            <StyledTextField
              variant="outlined"
              sx={{
                "& fieldset": {
                  border: "1px solid #79C2D8",
                  borderRadius: "1000px",
                  margin: 0,
                },
                width: "100%",
                maxWidth: "600px",
                margin: 0,
              }}
              InputProps={{
                inputProps: {
                  style: {
                    textAlign: "left",
                    borderRadius: "100rem",
                    height: "20px",
                    background: "white",
                  },
                },
              }}
              onChange={(e) => setAnnouncementSubject(e.target.value)}
              margin="normal"
              id="subject"
              label="Subject"
              name="subject"
            />
          )}

          <CustomTextField
            onChange={(e) => {
              setAnnouncementMessage(e);
            }}
            label="Message"
            multiline
          />

          <Row
            crossAxisAlignment="center"
            spacing={50}
            style={{ marginTop: "5rem" }}
          >
            <Button
              variant="contained"
              sx={{
                width: "550px",
                height: "50px",
                borderRadius: "100rem",
                backgroundColor: "white",
              }}
              onClick={() =>
                cancelAction
                  ? setCancelAction(false)
                  : setAddAnnouncement(false)
              }
            >
              <Typography
                style={{
                  color: "#0CAF6D",
                }}
              >
                Discard
              </Typography>
            </Button>

            <Button
              variant="contained"
              sx={{
                width: "550px",
                height: "50px",
                borderRadius: "100rem",
                backgroundColor: "white",
              }}
            >
              <Typography
                style={{
                  color: "#0CAF6D",
                }}
              >
                Save Draft
              </Typography>
            </Button>

            <Button
              variant="contained"
              sx={{
                width: "550px",
                height: "50px",
                borderRadius: "100rem",
                backgroundColor: "#0CAF6D",
              }}
              onClick={
                cancelAction ? handleSendAnnouncement1 : handleSendAnnouncement
              }
            >
              Send
            </Button>
          </Row>
        </StyledDialog>

        <Helmet>
          <title>{eventDetails?.event_name}</title>
          <meta property="og:title" content={eventDetails?.event_name} />
          <meta property="og:description" content={eventDetails?.description} />
          <meta property="og:image" content={eventDetails?.poster} />
          <meta
            property="og:url"
            content={`https://eventsqa.qasa.me/e/${eventDetails?.purl}`}
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={eventDetails?.event_name} />
          <meta
            name="twitter:description"
            content={eventDetails?.description}
          />
          <meta name="twitter:image" content={eventDetails?.poster} />
        </Helmet>
        <CreateEventWrapper>
          <Row
            mainAxisAlignment="space-between"
            crossAxisAlignment="end"
            sx={{ marginTop: "3rem" }}
          >
            <Column>
              {/* <HeaderGreenTopBar />
              <HeaderText>NEW EVENT</HeaderText> */}
              <HeaderGreenTopBar />
              {displayStatus === "cancelled" ? (
                <HeaderText>CANCELLED EVENT</HeaderText>
              ) : (
                <HeaderText
                  onClick={() => {
                    navigate(
                      `/create-event?id=${eventDetails?.id}&status=${eventDetails?.display_status}`
                    );
                  }}
                >
                  EDIT EVENT
                </HeaderText>
              )}
            </Column>

            <Row
              crossAxisAlignment="center"
              spacing={10}
              sx={{ ":hover": { cursor: "pointer" } }}
            >
              {displayStatus === "cancelled" ? (
                <HeaderOption
                  onClick={() => {
                    setShowPopUp2(true);
                  }}
                  style={{
                    fontSize: "0.8rem",
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  DELETE EVENT
                  <img
                    src={Bin}
                    alt="bin"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "10px",
                      marginTop: "0",
                    }}
                  />
                </HeaderOption>
              ) : (
                <HeaderOption
                  onClick={() => {
                    setShowPopUp1(true);
                  }}
                  style={{
                    fontSize: "0.8rem",
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  CANCEL EVENT
                  <img
                    src={Bin}
                    alt="bin"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "10px",
                      marginTop: "0",
                    }}
                  />
                </HeaderOption>
              )}

              {/* <HeaderOption
                onClick={() => {
                  navigate(
                    `/create-event?id=${eventDetails?.id}&status=${eventDetails?.display_status}`
                  );
                }}
              >
                EDIT EVENT
              </HeaderOption> */}
            </Row>
          </Row>
          <br />
          {eventDetails && (
            <section className="event-details-section">
              <div className="event-image-flyer">
                <Box
                  // component="img"
                  sx={{
                    height: "50vh",
                    width: "100%",
                    objectFit: "cover",
                  }}
                >
                  <VaryingWidthImage
                    imageUrl={eventDetails.poster}
                    blurImageUrl={eventDetails.poster}
                    imageList={(eventDetails?.image_sliders ?? [])?.map(
                      (x: any) => x.image_link
                    )}
                    disableSlider={false}
                  />
                </Box>
              </div>
              <div className="flex-container">
                <div className="flex-item">
                  <div className="events">
                    <HeaderGreenTopBar />
                    <h2 className="event-content-header">EVENT INFORMATION</h2>
                    <p className="event-content-sub-header">
                      Event Name<br></br>
                      <span className="event-content-sub-info">
                        {" "}
                        {eventDetails.event_name}
                      </span>
                    </p>
                    <p className="event-content-sub-header">
                      Event Date<br></br>
                      <span className="event-content-sub-info">
                        {" "}
                        {formattedDate}
                      </span>
                    </p>
                    <p className="event-content-sub-header">
                      Event Time<br></br>
                      <span className="event-content-sub-info">
                        {" "}
                        {extractTimeFromDate(event_date)}{" "}
                      </span>
                    </p>
                    <p className="event-content-sub-header">
                      Event Address<br></br>
                      <span className="event-content-sub-info">
                        {eventDetails.venue}
                      </span>
                    </p>
                    <p className="event-content-sub-header">
                      Event Description<br></br>
                      <span className="event-content-sub-info">
                        {eventDetails.description ??
                          "Checkout this awesome event"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="vertical-separator"></div>
                <div className="flex-item">
                  <div className="events">
                    <HeaderGreenTopBar />
                    <h2 className="event-content-header">EVENT PRICES</h2>

                    {eventTickets?.map((ticket, index) => (
                      <div className="event-pricing-content">
                        <div className="pricing-info">
                          {ticket.ticket_name}
                          <br></br>
                          <span className="event-content-sub-info">
                            {ticket.price != 0 ? ticket.currency : ""}
                            {ticket.price != 0 ? ticket.price : "Free"}
                            &nbsp;
                            <br />
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          )}
          {eventDetails?.display_status == DisplayStatusEnum.draft ||
            (eventDetails?.display_status == DisplayStatusEnum.cancelled &&
              displayStatus !== "cancelled" && (
                <Row spacing={40} sx={{ marginBottom: "40rem", width: "100%" }}>
                  <Button
                    variant="outlined"
                    sx={{
                      width: "100%",
                      marginTop: "5rem",
                      height: "48px",
                      borderRadius: "100rem",
                      borderColor: "#0CAF6D",
                      color: "#0CAF6D",
                    }}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Back
                  </Button>
                  {
                    <Button
                      variant="contained"
                      sx={{
                        width: "100%",
                        marginTop: "5rem",
                        height: "48px",
                        borderRadius: "100rem",
                        backgroundColor: "#0CAF6D",
                      }}
                      onClick={() => handlePublish()}
                    >
                      Publish
                    </Button>
                  }
                </Row>
              ))}
          <div className="page-wrapper">
            {(eventDetails?.display_status !== DisplayStatusEnum.draft) && (
              <div>
                <Row
                  mainAxisAlignment="space-between"
                  crossAxisAlignment="end"
                  style={{ marginTop: "3rem", width: "100%" }}
                >
                  <Column>
                    <HeaderGreenTopBar />
                    <HeaderText>Analytics Overview</HeaderText>
                  </Column>

                  <div
                    onClick={() => {
                      navigate("/analytics");
                    }}
                    style={{
                      marginLeft: "auto",
                      padding: "10px 20px",
                      borderRadius: "15rem",
                      background: "#0CAF6D",
                      color: "white",
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <span>View All Analytics</span>
                    <img
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                      src={AddSvg}
                    />
                  </div>
                </Row>
                <Row>
                  <div
                    style={{
                      height: "48px",
                      borderRadius: "100rem",
                      backgroundColor: " #FAFBFC",
                      display: "flex",
                      marginTop: "2rem",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "5px 0 10px 10px",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        paddingRight: "50px",
                      }}
                    >
                      Number of Tickets Available
                    </span>
                    <div
                      style={{
                        height: "48px",
                        borderRadius: "100rem",
                        backgroundColor: "#EDF2F7",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        padding: "5px 20px",
                        cursor: "pointer",
                        gap: "1rem",
                        width: "150px",
                      }}
                      onClick={() => {
                        // setAddAnnouncement(true);
                      }}
                    >
                      {eventTickets.reduce(
                        (total, ticket) => total + ticket.quantity,
                        0
                      )}
                    </div>
                  </div>
                </Row>
                <Row>
                  <div
                    style={{
                      height: "48px",
                      borderRadius: "100rem",
                      backgroundColor: " #FAFBFC",
                      display: "flex",
                      marginTop: "2rem",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "5px 0 10px 10px",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        paddingRight: "35px",
                      }}
                    >
                      Number of Tickets Purchased
                    </span>
                    <div
                      style={{
                        height: "48px",
                        borderRadius: "100rem",
                        backgroundColor: "#EDF2F7",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        padding: "5px 20px",
                        cursor: "pointer",
                        gap: "1rem",
                        width: "150px",
                      }}
                      onClick={() => {
                        // setAddAnnouncement(true);
                      }}
                    >
                      {eventTickets.reduce(
                        (total, ticket) =>
                          total + (ticket.initial_quantity - ticket.quantity),
                        0
                      )}
                    </div>
                  </div>
                </Row>
                <Row>
                  <div
                    style={{
                      height: "48px",
                      borderRadius: "100rem",
                      backgroundColor: " #FAFBFC",
                      display: "flex",
                      marginTop: "2rem",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "5px 0 10px 10px",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        paddingRight: "50px",
                      }}
                    >
                      Number of Cash Purchases
                    </span>
                    <div
                      style={{
                        height: "48px",
                        borderRadius: "100rem",
                        backgroundColor: "#EDF2F7",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        padding: "5px 20px",
                        cursor: "pointer",
                        gap: "1rem",
                        width: "150px",
                      }}
                      onClick={() => {
                        // setAddAnnouncement(true);
                      }}
                    ></div>
                  </div>
                </Row>
              </div>
            )}

            {displayStatus !== "cancelled" ? (
              <>
                <Row
                  mainAxisAlignment="space-between"
                  crossAxisAlignment="end"
                  style={{ marginTop: "3rem", width: "100%" }}
                >
                  <Column style={{ width: "200px" }}>
                    <HeaderGreenTopBar />
                    <HeaderText>EVENT STAFF</HeaderText>
                  </Column>

                  <div
                    onClick={() => {
                      setNewGroupAdmins([
                        ...newGroupAdmins,
                        { phone: "", staffName: "", countryCode: "" },
                      ]);
                    }}
                    style={{
                      padding: "10px 20px",
                      borderRadius: "15rem",
                      background: "#0CAF6D",
                      color: "white",
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <span>Add Event Staff</span>
                    <img
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                      src={AddSvg}
                    />
                  </div>
                </Row>
                <Column spacing={20} style={{ marginTop: "2rem" }}>
                  {newGroupAdmins?.map((newStaff: any, index) => (
                    <div
                      style={{
                        borderRadius: "100rem",
                        height: "60px",
                        width: "100%",
                        maxWidth: "495px",
                        background: "rgba(250, 251, 252, 1)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        {/* Country code dropdown */}
                        <TextField
                          sx={{
                            "& fieldset": { border: "none" },
                            minWidth: "170px", // Set your desired minWidth here
                          }}
                          select
                          value={newStaff.countryCode}
                          onChange={(e) => handleCountryCodeChange(e, index)}
                          variant="outlined"
                          SelectProps={{
                            displayEmpty: true,
                          }}
                        >
                          {/* Default empty option */}
                          <MenuItem value="" disabled>
                            Select Country Code
                          </MenuItem>

                          {/* Country code options */}
                          {Object.keys(country)?.map((key: string) => (
                            <MenuItem
                              value={`${country[key].code} ${country[key].name}`}
                            >{`${country[key].code} (${country[key].name})`}</MenuItem>
                          ))}
                          {/* Add more country codes as needed */}
                        </TextField>

                        {/* Spacer between dropdown and TextField */}
                        <Box width={10} />

                        {/* Actual text field for phone number */}
                        <TextField
                          label="Phone Number"
                          variant="outlined"
                          fullWidth
                          onPaste={(e) => handlePaste(e, index)}
                          onChange={(e) => handleChange(e, index)}
                          sx={{
                            "& fieldset": { border: "none" },
                          }}
                          value={newStaff.phone}
                        />
                        <TextField
                          label="Staff Name"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            const updatedNewGroupAdmins = newGroupAdmins?.map(
                              (nga, ind) => {
                                if (ind === index) {
                                  return { ...nga, staffName: e.target.value };
                                }
                                return nga;
                              }
                            );
                            setNewGroupAdmins(updatedNewGroupAdmins);
                          }}
                          sx={{
                            "& fieldset": { border: "none" },
                          }}
                          value={newStaff.staffName}
                        />
                      </Box>
                      <div
                        onClick={() => {
                          const filteredNewAdmins = newGroupAdmins.filter(
                            (x, ind) => index !== ind
                          );
                          setNewGroupAdmins(filteredNewAdmins);
                        }}
                        style={{
                          height: "60px",
                          minWidth: "60px",

                          width: "60px",
                          borderRadius: "100rem",
                          background: "rgba(237, 242, 247, 1)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{
                            width: "24px",
                            height: "24px",
                            color: "rgba(12, 175, 109, 1)",
                          }}
                          src={TrashSvg}
                        />
                      </div>
                    </div>
                  ))}
                </Column>
                <Column spacing={20} style={{ marginTop: "2rem" }}>
                  {groupAdmins
                    .filter(
                      (x) =>
                        x.group_member_status === "ADMIN" &&
                        `${x.phone}` !== userDetails?.phone
                    )
                    ?.map((admin: any, index) => (
                      <div
                        style={{
                          borderRadius: "100rem",
                          height: "60px",
                          width: "100%",
                          maxWidth: "495px",
                          background: "rgba(250, 251, 252, 1)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <BodyText style={{ marginLeft: "30px" }}>
                          {formatPhoneNumber(admin?.phone)}
                        </BodyText>
                        <div
                          onClick={() => {
                            EventRegister.emit(EventsEnum.Speedbump, {
                              text: "Do you want to remove this staff member from you group?",
                              function: async () => {
                                const makeGroupAdminRes =
                                  await apiDeleteRequest(
                                    `/group-member/`,

                                    {
                                      auth: userDetails?.access_token,
                                      content_type:
                                        ContentTypeEnum.APPLICATION_JSON,
                                    },
                                    true,
                                    {
                                      member_id: admin.id,
                                      group_id: eventDetails?.group_id,
                                    }
                                  );
                                if (makeGroupAdminRes?.code == "00") {
                                  toast(
                                    makeGroupAdminRes?.msg ??
                                      "Action Successful",
                                    { type: ErrorSeverityEnum.success }
                                  );
                                  const filteredNewAdmins =
                                    newGroupAdmins.filter(
                                      (x, ind) => index !== ind
                                    );
                                  setNewGroupAdmins(filteredNewAdmins);
                                } else {
                                  toast(errorMessage, {
                                    type: ErrorSeverityEnum.error,
                                  });
                                }
                              },
                            });
                          }}
                          style={{
                            height: "60px",
                            width: "60px",
                            borderRadius: "100rem",
                            background: "rgba(237, 242, 247, 1)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{
                              width: "24px",
                              height: "24px",
                              color: "rgba(12, 175, 109, 1)",
                            }}
                            src={TrashSvg}
                          />
                        </div>
                      </div>
                    ))}

                  {newGroupAdmins.length > 0 && (
                    <Button
                      variant="contained"
                      sx={{
                        width: "100%",
                        marginTop: "2rem",
                        height: "48px",
                        borderRadius: "100rem",
                        backgroundColor: "#0CAF6D",
                      }}
                      disabled={!allValuesFilled()}
                      onClick={() => handleAddNewStaff()}
                    >
                      Save New Event Staff
                    </Button>
                  )}
                </Column>
              </>
            ) : (
              ""
            )}

            {displayStatus !== "cancelled" ? (
              <>
                <Row
                  // className="event-administration"
                  mainAxisAlignment="start"
                  crossAxisAlignment="center"
                  style={{ marginTop: "10rem", width: "100%" }}
                >
                  <Column>
                    <HeaderGreenTopBar />
                    <HeaderText>PROMO CODES</HeaderText>
                  </Column>

                  <div
                    onClick={() => {
                      setAddPromoCode(true);
                    }}
                    style={{
                      marginLeft: "auto",
                      padding: "10px 20px",
                      borderRadius: "15rem",
                      background: "#0CAF6D",
                      color: "white",
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <span>Create Promo Code</span>
                    <img
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                      src={AddSvg}
                    />
                  </div>
                </Row>
                <Column style={{ marginTop: "3rem" }}>
                  {promoCodeData?.map((promoCode: any, index) => {
                    return (
                      <Row
                        style={{
                          marginTop: "2rem",
                          width: "100%",
                          gap: "1rem",
                          alignItems:"center"
                        }}
                        key={index}
                      >
                        <ViewPromoCodePopup
                          handleEditStatus={async () => {
                            const res = await apiPutRequest(
                              `${urls.events}disable_promo_code/${promoCode?.id}`,
                              {
                                group_id: eventDetails?.group_id,
                              },
                              {
                                auth: userDetails.access_token,
                                content_type: ContentTypeEnum.MULTIPART_FORM,
                              }
                            );
                            // console.log("API Response:", res);
                            if (res?.code == "00") {
                              toast("Promo code toggled", {
                                type: ErrorSeverityEnum.success,
                              });
                              initFunction();
                              // window.location.reload();
                            }
                          }}
                          promoDetails={promoCode}
                          open={selectedPromoCode == promoCode.id}
                          onClose={() => {
                            setSelectedPromoCode(null);
                          }}
                        />
                        {/* <AnnouncementContainer>
                          <AnnouncementText>{promoCode.code}</AnnouncementText>
                        </AnnouncementContainer>

                        <DateContainer>
                          <Typography>
                          {promoCode.code}
                          </Typography>
                        </DateContainer>
                        <DateContainer>
                          <Typography>
                            {promoCode.discount_type === "percentage"
                              ? `${promoCode.discount_value}% Off`
                              : `${promoCode.discount_value} Cash Off`}
                          </Typography>
                        </DateContainer> */}

                        <div
                          style={{
                            background: "rgba(250, 251, 252, 1)",
                            padding: "20px 20px",
                            borderRadius: "100rem",
                            width: "fit-content",
                          }}
                        >
                          {promoCode.code}
                        </div>
                        <div
                          style={{
                            background: "rgba(250, 251, 252, 1)",
                            padding: "20px 20px",
                            borderRadius: "100rem",
                            width: "fit-content",
                          }}
                        >
                          {promoCode.discount_type === "percentage"
                            ? `${promoCode.discount_value}% Off`
                            : `${promoCode.discount_value} Cash Off`}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {/* <img src={Bin} alt="bin" /> */}
                          <VisibilityOutlined
                            style={{ color: "#0CAF6D" }}
                            onClick={() => setSelectedPromoCode(promoCode.id)}
                          />
                          <ToggleSwitchWhiteKnob
                            checked={promoCode.status === "Y"}
                            handleChange={async () => {
                              const res = await apiPutRequest(
                                `${urls.events}disable_promo_code/${promoCode?.id}`,
                                {
                                  group_id: eventDetails?.group_id,
                                },
                                {
                                  auth: userDetails.access_token,
                                  content_type: ContentTypeEnum.MULTIPART_FORM,
                                }
                              );
                              // console.log("API Response:", res);
                              if (res?.code == "00") {
                                toast("Promo code toggled", {
                                  type: ErrorSeverityEnum.success,
                                });
                                initFunction();
                                // window.location.reload();
                              }
                            }}
                          />
                          <span style={{fontSize:"16px", fontWeight:"bold", color:"rgba(9,10,13,1)"}}>{promoCode.status === "Y"?"Disable":"Enable"}</span>
                        </div>
                      </Row>
                    );
                  })}
                </Column>
              </>
            ) : (
              ""
            )}

            {displayStatus !== "cancelled" ? (
              <>
                <Row
                  className="event-administration"
                  mainAxisAlignment="start"
                  crossAxisAlignment="end"
                  style={{ marginTop: "10rem" }}
                >
                  <Column>
                    <HeaderGreenTopBar />
                    <HeaderText>ANNOUNCEMENTS</HeaderText>
                  </Column>

                  <div
                    onClick={() => {
                      setAddAnnouncement(true);
                      setSendSMS(true);
                    }}
                    style={{
                      marginLeft: "auto",
                      padding: "10px 20px",
                      borderRadius: "15rem",
                      background: "#0CAF6D",
                      color: "white",
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <span>Create Announcement</span>
                    <img
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                      src={AddSvg}
                    />
                  </div>
                </Row>
                <Column style={{ marginTop: "3rem" }}>
                  {announcements?.map((announcement: any, index) => (
                    <>
                      <Row
                        style={{ marginTop: "2rem", width: "100%" }}
                        key={index}
                      >
                        <AnnouncementContainer>
                          <AnnouncementText>
                            {announcement.content}
                          </AnnouncementText>
                        </AnnouncementContainer>

                        <DateContainer>
                          <Typography>
                            {formatDate(announcement.date_created)}
                          </Typography>
                        </DateContainer>
                      </Row>
                    </>
                  ))}
                </Column>
              </>
            ) : (
              ""
            )}
          </div>

          {displayStatus === "cancelled"}

          {eventDetails?.display_status !== DisplayStatusEnum.draft &&
            displayStatus !== "cancelled" && (
              <Row spacing={40} sx={{ marginBottom: "40rem" }}>
                <Button
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginTop: "5rem",
                    height: "48px",
                    borderRadius: "100rem",
                    borderColor: "#0CAF6D",
                    color: "#0CAF6D",
                  }}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                {eventDetails?.display_status !== DisplayStatusEnum.live && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      marginTop: "5rem",
                      height: "48px",
                      borderRadius: "100rem",
                      backgroundColor: "#0CAF6D",
                    }}
                    onClick={() => {
                      setShowPopUp(true);
                    }}
                  >
                    Unpublish
                  </Button>
                )}
              </Row>
            )}
        </CreateEventWrapper>
      </>
    </>
  );
}

export default EventInformation;

const CreateEventWrapper = styled.div`
  min-height: calc(100vh - 80px);
  .page-wrapper {
    width: 100%;
  }
  @media (min-width: 700px) {
    .page-wrapper {
      width: 80%;
    }
  }

  @media (min-width: 900px) {
    .page-wrapper {
      width: 45%;
    }
  }
`;

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    background: rgba(37, 94, 135, 1);
    border-radius: 16px 0 16px 16px;
    padding: 2rem;
    color: white;
  }
`;

const RedCheckbox = styled(Radio)(({ theme }) => ({
  color: "#79C2D8",
  "&.Mui-checked": {
    color: "#79C2D8",
  },
}));

const AnnouncementContainer = styled.div`
  border-radius: 100rem;
  width: 100%;
  height: "60px";
  width: "100%";
  maxwidth: "755px";
  background: rgba(250, 251, 252, 1);
  display: flex;
  align-items: center;
  padding: 10px 25px;
  margin-bottom: 15px;
  margin-right: 10px;
  flex-grow: 1;

  @media (min-width: 768px) {
    max-width: 755px;
    justify-content: space-between;
  }
`;

const DateContainer = styled.div`
  border-radius: 100rem;
  height: "60px";
  width: 100%;
  max-width: 165px;
  background: rgba(250, 251, 252, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  @media (min-width: 768px) {
    max-width: 755px;
    justify-content: center;
  }
`;

const AnnouncementText = styled(Typography)`
  flex-grow: 1;
`;

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
