import styled from "styled-components";
import { DisplayStatusEnum, EventItem } from "../_helpers/types";
import { Column, Row } from "../../_shared/styledComponents";
import CalendarSvg from "../../_shared/assets/svg/Calender.svg";
import ClockSvg from "../../_shared/assets/svg/Clock.svg";
import LocationPinSvg from "../../_shared/assets/svg/Location.svg";
import ShareSvg from "../../_shared/assets/svg/share.svg";
import PencilSvg from "../../_shared/assets/svg/pencil.svg";
import VaryingWidthImage from "../../_shared/components/imageViewer";
import { Box, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiGetRequest } from "../../_shared/api";
import { errorMessage, urls } from "../../_shared/constants";
import QrCodeGenerator from "../../Event_Admin/QrCodeGenerator";
import { ErrorSeverityEnum } from "../../_shared/enum/error-severity.enum";
import { toast } from "react-toastify";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { Ticket } from "../../EventDetails/interfaces";
type Props = {
  event: EventItem;
  type?: string;
};
const EventCardNew = (props: Props) => {
  const { event, type } = props;
  const navigate = useNavigate();
  const [minPrice, setMinPrice] = useState("");
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [eventDetails, setEventDetails] = useState<any>(null);
  const [isSoldOut, setIsSoldOut] = useState(false);

  // const handleShareClick = (event: any) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function handleClick() {
    if (type === "Published" || type === "Cancelled" || type === "Live" || type === "Draft" || type === "Past") {
      navigate(`/ea/${props.event.purl}`);
    } else {
      if (event.allTickets && [...event.allTickets].length > 0) {
        // console.log(props.event.allTickets,props.event.allTickets.map((tic:any)=>tic.receipt_id))
        window.open(
          `/payment-confirmation?receipt_ids=${props.event.allTickets.map(
            (tic: any) => `${tic.receipt_id}-${tic.id}`
          )}`,
          "_blank"
        );
      } else {
        if (props.event.purl) navigate(`/e/${props.event.purl}`);
        else navigate(`/e?eventId=${props.event.event_id}`);
      }
    }
  }
  const initFunct = async () => {
    const res = await apiGetRequest(
      `${urls.events}${event.event_id ?? event.id}`,
      undefined,
      false
    );
    if (res?.data != null) {
      setEventDetails(res.data);
      const eventTickets = res.data.tickets ?? [];
  
      // Filter out expired tickets based on their status
      const validTickets = eventTickets.filter((ticket: Ticket) => ticket.expired !== true);
  
      const allSoldOut = validTickets.every(
        (ticket: Ticket) => ticket.quantity <= 0
      );
      setIsSoldOut(allSoldOut);
  
      const startingPrice = `${
        validTickets.length > 0
          ? (() => {
              const sortedTickets = validTickets
                .slice()
                .sort((a: any, b: any) => a.price - b.price);
              const lowestPriceTicket = sortedTickets[0];
              return lowestPriceTicket
                ? `${lowestPriceTicket.currency}${lowestPriceTicket.price}`
                : "Free";
            })()
          : "???"
      }`;
      setMinPrice(startingPrice);
    }
  };
  useEffect(() => {
    initFunct();
  }, []);

  // useEffect(() => {
  //   const setMetaTags = () => {
  //     if (event) {
  //       const title = event.event_name || "Qasa Events";
  //       const image = event.poster;
  //       const description = `Join us at ${event.event_name} on ${event.event_date} at ${event.venue}.`;

  //       document.title = title;

  //       const metaTags = [
  //         { name: "og:title", content: title },
  //         { name: "og:image", content: image },
  //         { name: "og:description", content: description },
  //         { name: "twitter:title", content: title },
  //         { name: "twitter:image", content: image },
  //         { name: "twitter:description", content: description },
  //       ];

  //       metaTags.forEach(({ name, content }) => {
  //         let tag = document.querySelector(`meta[property='${name}']`) || document.createElement("meta");
  //         tag.setAttribute("property", name);
  //         tag.setAttribute("content", content);
  //         document.head.appendChild(tag);
  //       });
  //     }
  //   };

  //   setMetaTags();
  // }, [event]);

  const handleShareClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const url = `${
    process.env.REACT_APP_NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_QASA_EVENTS_URL ?? ""
      : process.env.REACT_APP_PROD_QASA_EVENTS_URL ?? ""
  }e/${`${event.event_name}`
    .toLocaleLowerCase()
    .replace(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, "")
    .replaceAll(" ", "-")}`;

  const encodedLink = encodeURIComponent(url);
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
  const twitterUrl = `https://twitter.com/intent/tweet?url=${encodedLink}`;
  const instagramUrl = `https://www.instagram.com/?url=${encodedLink}`;

  const handleDownloadQRCode = (event_id: string) => {
    const divToPrint = document.getElementById(`divToPrint-${event_id}`);
    if (divToPrint) {
      const innerElements = divToPrint.querySelectorAll("*");
      innerElements.forEach((element: any) => {
        element.style.display = "";
      });

      const newWindow = window.open("", "_blank");
      if (newWindow) {
        newWindow.document.write(
          `<html><head><title>Print</title>
          <style>
              body {
                width: 100vw;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 0;
                padding: 0;
              }
              .header { 
                text-align: center;
                margin-bottom: 1rem;
                width: 100vw;
                background: #C6E2ED;
                padding: 50px 0;
               }
               .header h1 {
                border: 3px solid black;
                width: fit-content;
                margin: auto;
                padding: 20px;
  
               }
               .row{
                display: flex;
                justify-content: center;
                gap:1rem;
               }
               .download-button{
                padding: 10px 20px;
                margin-top: 50px;
                border: 1px solid #bbb;
                border-radius: 5px;
                font-size: 16px;
               }
  
               @media print {
  
                .download-button {
                    display: none !important; /* Hide the button when printing */
                }
            }
            </style>
          </head><body style="width: 100vw;">`
        );
        newWindow.document.write(divToPrint.innerHTML);
        const downloadButton = document.createElement("button");
        downloadButton.innerText = "Download";
        downloadButton.className = "download-button";

        downloadButton.onclick = () => {
          newWindow?.print();
        };
        newWindow?.document.body.appendChild(downloadButton);
        newWindow.document.write("</body></html>");
        newWindow.document.close();
      } else {
        console.error(
          "Unable to open new window. Please check your browser settings."
        );
      }
      innerElements.forEach((element: any) => {
        element.style.display = "none";
      });
    }
  };

  const handleDownloadQRCode1 = (event: EventItem) => {
    const url = `/qr-code?eventId=${event.event_id ?? event.id}`;
    window.open(url, "_blank");
  };
  const sanitizeTimezone = (timezone: string): string => {
    return timezone.replace(",", "/").replace(" ", "");
  };

  const extractTimeFromDate = (dateStr: string | number | Date) => {
    if (!event?.event_timezone) {
      return "";
    }

    const sanitizedTimezone = sanitizeTimezone(event.event_timezone);
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: sanitizedTimezone,
      timeZoneName: "short",
    };

    try {
      const formatter = new Intl.DateTimeFormat("en-US", options);
      const formattedDateParts = formatter.formatToParts(date);

      let timeString = "";
      let timeZone = "";

      formattedDateParts.forEach(({ type, value }) => {
        if (type === "hour" || type === "minute" || type === "second") {
          timeString += value.padStart(2, "0") + ":";
        }
        if (type === "timeZoneName") {
          timeZone = value;
        }
      });

      timeString = timeString.slice(0, -1);

      return `${timeString} (${timeZone})`;
    } catch (error) {
      console.error("Invalid timezone:", sanitizedTimezone);
      return "Invalid timezone";
    }
  };

  return (
    <EventCardSuperWrapper>
      <EventCardWrapper onClick={handleClick}>
        {event.allTickets && event.allTickets.length > 0 ? (
          <SoldOutBanner>
            {event.allTickets.length} Tickets Available
          </SoldOutBanner>
        ) : (
          isSoldOut && <SoldOutBanner>SOLD OUT!</SoldOutBanner>
        )}
        <Box
          sx={{
            height: "260px",
            width: "100%",
            objectFit: "cover",
            borderRadius: "15px 15px 0 0",
          }}
        >
          <VaryingWidthImage
            imageUrl={event.poster ?? eventDetails?.poster}
            blurImageUrl={event.poster ?? eventDetails?.poster}
            // imageList={
            //   event?.image_sliders ?? eventDetails?.image_sliders ?? []
            // }
            imageList={(event?.image_sliders ?? []).map(
              (x: any) => x.image_link
            )}
            borderRadius="15px 15px 0 0"
            disableSlider={false}
          />{" "}
        </Box>

        <BodyWrapper>
          <HeaderText>{event.event_name}</HeaderText>
          <Column spacing={5}>
            <Row>
              <img
                style={{
                  width: "18px",
                  height: "18px",
                  marginRight: "5px",
                }}
                src={CalendarSvg}
              />
              <BodyText>
                {(event.event_date ?? "").length > 0
                  ? event.event_date.split(" ").slice(0, 4).join(" ")
                  : ""}
              </BodyText>
            </Row>
            <Row>
              <img
                style={{
                  width: "18px",
                  height: "18px",
                  marginRight: "5px",
                }}
                src={ClockSvg}
              />
              <BodyText>
                {extractTimeFromDate(event.event_date)
                  ? extractTimeFromDate(event.event_date)
                  : (event.event_date ?? "").length > 0
                  ? event.event_date.split(" ").slice(4, 6).join(" ")
                  : ""}
              </BodyText>
            </Row>
            <Row>
              <img
                style={{
                  width: "18px",
                  height: "18px",
                  marginRight: "5px",
                }}
                src={LocationPinSvg}
              />
              <BodyText>{event.venue}</BodyText>
            </Row>
          </Column>
        </BodyWrapper>

        <Footer>
          <BodyText>Price starts at:</BodyText>
          <PriceText>{minPrice}</PriceText>
        </Footer>
      </EventCardWrapper>
      <CardRhsContainer>
        {event.display_status == DisplayStatusEnum.draft && (
          <Tooltip title="Edit">
            <RhsSubContainer
              onClick={() => {
                navigate(`/create-event?id=${event.id}&status=draft`);
              }}
            >
              {/* <img
              style={{
                width: "24px",
                height: "24px",
              }}
              src={PencilSvg}
            /> */}
              <ModeEditOutlineIcon sx={{ color: "white" }} />
            </RhsSubContainer>
          </Tooltip>
        )}
        {
          <Tooltip title="Share">
            <RhsSubContainer>
              <img
                style={{
                  width: "24px",
                  height: "24px",
                }}
                onClick={(event) => handleShareClick(event)}
                src={ShareSvg}
              />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {[
                  {
                    title: "Qr Code",
                    function: () => {
                      handleDownloadQRCode1(props.event);
                    },
                  },
                  {
                    title: "Copy Link",
                    function: () => {
                      navigator.clipboard
                        .writeText(
                          `${
                            process.env.REACT_APP_NODE_ENV == "development"
                              ? process.env.REACT_APP_DEV_QASA_EVENTS_URL ?? ""
                              : process.env.REACT_APP_PROD_QASA_EVENTS_URL ?? ""
                          }e/${`${props.event.event_name}`
                            .toLocaleLowerCase()
                            .replace(
                              /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g,
                              ""
                            )
                            .replaceAll(" ", "-")}`
                        )
                        .then(() => {
                          toast("Text Copied Successfully", {
                            type: ErrorSeverityEnum.success,
                          });
                        })
                        .catch((err) => {
                          toast(errorMessage, {
                            type: ErrorSeverityEnum.error,
                          });
                        });
                    },
                  },
                  {
                    title: "Print",
                    function: () => {
                      const divToPrint = document.getElementById(
                        `divToPrint-${props.event.id}`
                      );
                      if (divToPrint) {
                        const innerElements = divToPrint.querySelectorAll("*");
                        innerElements.forEach((element: any) => {
                          element.style.display = "";
                        });

                        const newWindow = window.open("", "_blank");
                        if (newWindow) {
                          newWindow.document.write(
                            `<html><head><title>Print</title>
                            <style>
                                body {
                                  width: 100vw;
                                  display: flex;
                                  flex-direction: column;
                                  align-items: center;
                                  margin: 0;
                                  padding: 0;
                                }
                                .header { 
                                  text-align: center;
                                  margin-bottom: 1rem;
                                  width: 100vw;
                                  background: #C6E2ED;
                                  padding: 50px 0;
                                 }
                                 .header h1 {
                                  border: 3px solid black;
                                  width: fit-content;
                                  margin: auto;
                                  padding: 20px;
                  
                                 }
                                 .row{
                                  display: flex;
                                  justify-content: center;
                                  gap:1rem;
                                 }
                                 .download-button{
                                  padding: 10px 20px;
                                  margin-top: 50px;
                                  border: 1px solid #bbb;
                                  border-radius: 5px;
                                  font-size: 16px;
                                 }
                  
                                 @media print {
                  
                                  .download-button {
                                      display: none !important; /* Hide the button when printing */
                                  }
                              }
                              </style>
                            </head><body style="width: 100vw;">`
                          );
                          newWindow.document.write(divToPrint.innerHTML);

                          newWindow.document.write("</body></html>");
                          newWindow.document.close();
                          newWindow?.print();
                        } else {
                          console.error(
                            "Unable to open new window. Please check your browser settings."
                          );
                        }
                        innerElements.forEach((element: any) => {
                          element.style.display = "none";
                        });
                      }
                    },
                  },
                  {
                    title: "Facebook",
                    function: () => {
                      window.open(facebookUrl, "_blank");
                    },
                  },
                  {
                    title: "Twitter",
                    function: () => {
                      window.open(twitterUrl, "_blank");
                    },
                  },
                ].map((item, index) => (
                  <MenuItem key={index} onClick={() => item.function()}>
                    {item.title}
                  </MenuItem>
                ))}
              </Menu>
            </RhsSubContainer>
          </Tooltip>
        }
        <>
          <Stack direction={"column"} id={`divToPrint-${props.event.id}`}>
            <Stack style={{ display: "none", width: "100%" }}>
              <div className="header">
                <h1>
                  SCAN TO <br />
                  BUY
                  <br />
                  TICKETS
                </h1>
              </div>
              <Row className="row">
                <h1>{props.event.event_name}</h1>
              </Row>

              <Row className="row">
                <img
                  style={{
                    height: 300,
                    width: 300,
                    objectFit: "cover",
                    marginRight: 5,
                  }}
                  alt="image"
                  src={props.event.poster ?? eventDetails?.poster}
                />
                <QrCodeGenerator
                  url={`${
                    process.env.REACT_APP_NODE_ENV == "development"
                      ? process.env.REACT_APP_DEV_QASA_EVENTS_URL ?? ""
                      : process.env.REACT_APP_PROD_QASA_EVENTS_URL ?? ""
                  }e/${`${props.event.event_name}`
                    .toLocaleLowerCase()
                    .replace(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, "")
                    .replaceAll(" ", "-")}`}
                />
              </Row>
            </Stack>
          </Stack>
        </>
      </CardRhsContainer>
    </EventCardSuperWrapper>
  );
};

export default EventCardNew;

const EventCardSuperWrapper = styled.div`
  display: flex;
`;

const SoldOutBanner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: #255e87e5;
  color: white;
  padding: 5px 30px;
  font-weight: bold;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const EventCardWrapper = styled.div`
  position: relative;
  background: #f0f2f2;
  border-radius: 15px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  cursor: pointer !important;
  overflow: hidden;
`;

const BodyWrapper = styled.div`
  background: #f0f2f2;
  margin: 15px;
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.span`
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #090a0d !important;
  margin-bottom: 10px;
`;

const BodyText = styled.span`
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #5f6462 !important;
`;

const Footer = styled.div`
  border-top: 1px solid #cacdd1;
  padding: 15px;
  display: flex;
  justify-content: space-between;
`;
const PriceText = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: right;
  color: #0caf6d;
`;

const CardRhsContainer = styled.div`
  width: 45px;
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  gap: 10px;
`;

const RhsSubContainer = styled.div`
  background-color: #0caf6d;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
`;
