import { EventItem } from "../Home/_helpers/types";
import { ContentTypeEnum } from "./enum/content-type.enum";

export type headerType = {
  content_type: ContentTypeEnum;
  auth?: string;
};


export enum EventsEnum {
  Loading = "loadingEvent",
  CheckLoginStatus = "checkLoginStatus",
  LoginUser = "loginUser",
  Speedbump = "Speedbump",
}

export interface Country {
  code: string;
  name: string;
}

export interface CountryCodes {
  [key: string]: Country;
}


export interface SortedEvents {
  pastEvents: EventItem[];
  presentEvents: EventItem[];
  pubEvents?: EventItem[];
  draftEvents?: EventItem[];
  upcomingEvents?: EventItem[];
  cancelledEvents: EventItem[];
}