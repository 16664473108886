export function getUploadablePartOfB64(imageData: string) {
    if (imageData && isBase64String(imageData)) return imageData.split(",")[1];
    else return null;
}

// @ts-ignore
export function isBase64String(data: string | null | undefined) {
    if (data) return data.substr(0, 5) === "data:";
    else return false;
}

export function isNumber(value: any): boolean {
    // Convert the value to a number using parseFloat
    const number = parseFloat(value);
    
    // Check if the result is a number and not NaN
    return !isNaN(number) && isFinite(number);
}

interface Currency {
    alphabetic_currency_code: string;
    country_code: string;
    country_name: string;
    currency_name: string;
    currency_symbol: string;
    numeric_currency_code: number;
    sub_units: string;
    us_dollar_exchange_rate?: number | null;
    euro_exchange_rate?: number | null;
    exchange_rate_date?: string | null;
    fixed_rate?: number | null;
  }
  
  export function sortCurrenciesByValue(currencies: Currency[]): Currency[] {
    // Define the relative value ranking (1 is highest)
    const currencyValueRanking: { [key: string]: number } = {
      GBP: 1, // Pound Sterling
      EUR: 2, // Euro
      USD: 3, // United States Dollar
      CAD: 4, // Canadian Dollar
      ZAR: 5, // South African Rand
      EGP: 6, // Egyptian Pound
      GHS: 7, // Ghanaian Cedi
      NGN: 8  // Nigerian Naira
    };
  
    // Sort currencies based on the predefined value ranking
    return currencies.sort((a: Currency, b: Currency) => {
      const rankA = currencyValueRanking[a.alphabetic_currency_code] || 999;
      const rankB = currencyValueRanking[b.alphabetic_currency_code] || 999;
      return rankA - rankB;
    });
  }
  
  