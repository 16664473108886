import { TextField, styled } from "@mui/material";
import { Container } from "../_shared/styledComponents";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Login from "./Login";
import { OtpPage } from "./OtpPage";
import { apiPostRequest } from "../_shared/api";
import { count } from "console";
import { EventRegister } from "react-native-event-listeners";
import { EventsEnum } from "../_shared/types";

type Props = {
  handleCloseLogin: () => void;
};

const AuthPage = (props: Props) => {
  const {handleCloseLogin} = props;
  const [otp, setOtp] = useState<any>(''); // State to hold the selected country code
  const [viewOtp, setViewOtp] = useState<boolean>(false); // State to hold the selected country code
  const [phoneNumber, setPhoneNumber] = useState<string>(''); // State to hold the selected country code
  const [firstName, setFirstName] = useState<string>(''); // State to hold the selected country code
  const [lastName, setLastName] = useState<string>(''); // State to hold the selected country code
  const [country, setCountry] = useState<any>(''); // State to hold the selected country code

  const handleSendOtp = async (mobile: string, country: string, firstName_?: string, lastName_?: string ) => {
    const resp = await apiPostRequest('/users/send_otp', {mobile: mobile, country:country, name: `${firstName_??firstName} ${lastName_??lastName}`});
    if (resp.data.code){
      setOtp(resp.data.code);
      setViewOtp(true);
    }
  };

  const handleLogin = async () => {
    const resp = await apiPostRequest('/auth/login', {"type": 4, phone: phoneNumber, country: country, avatar: "https://qasaio.nyc3.digitaloceanspaces.com/default/single_user.png",name:`${firstName} ${lastName}`,open_id: "Test1234",});
    if(resp.code == "00"){
      localStorage.setItem('userDetails', JSON.stringify(resp.data));
      EventRegister.emit(EventsEnum.CheckLoginStatus, true);
      handleCloseLogin();
    }
  }

  return (<Container sx={{ position: "fixed", height: "100vh", width: "100vw", top: 0, left: 0, bottom: 0, right: 0, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <Container sx={{position:"fixed", top:20, right:20}} onClick={handleCloseLogin}>
          <CloseIcon sx={{color:"grey"}} fontSize="large"/>
      </Container>

    {viewOtp?<OtpPage loginFunct={()=> handleLogin()} otpCode={otp} handleSendOtp={function (): void {

        handleSendOtp(phoneNumber, country);
      } }  />:
    <Login setOtpCode={(val) => {
        setOtp(val);
      } }
      setViewOtp={(val: boolean) => {
        setViewOtp(val);
      } } handleSendOtp={function (mobile: string, country: string, firstName: string, lastName: string): void {
        setPhoneNumber(mobile);
        setFirstName(firstName);
        setLastName(lastName);
        setCountry(country);
        handleSendOtp(mobile, country, firstName, lastName);
      } }      />}



  </Container>);
}
export default AuthPage;

export const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(250, 251, 252, 1)",
  borderRadius: "100rem",
  border:"2px solid rgba(121, 194, 216, 1)",

  "& .MuiInputLabel-root": {
    textAlign: "left",
  },

  "& .MuiInputLabel-shrink": {
    margin: "19px auto",
    position: "absolute",
    left: "0",
    top: "-4px",
    width: "150px",
    background: "none",
  },

  "& .MuiOutlinedInput-root": {
    borderRadius: "100rem",
    backgroundColor: "rgba(250, 251, 252, 1)",
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
    "& input": {
      transition: theme.transitions.create("margin-top"),
    },
    // "& input:not(:placeholder-shown)": {
    //   marginTop: "5px", // Apply margin-top when there is text in the input
    // },
  },

  "& .MuiSelect-select": {
    backgroundColor: "rgba(250, 251, 252, 1)",
    borderRadius: "100px",
  },
  "& .MuiSelect-outlined": {
    backgroundColor: "rgba(250, 251, 252, 1)",
    borderRadius: "100rem",
  },
  "& .MuiInputBase-input": {
    backgroundColor: "rgba(250, 251, 252, 1)",
    borderRadius: "100rem",
  },
  "& .MuiOutlinedInput-input": {
    backgroundColor: "rgba(250, 251, 252, 1)",
    borderRadius: "100rem",
  },
  "& .css-b466pw-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
    {
      backgroundColor: "rgba(250, 251, 252, 1)",
      borderRadius: "100rem",
    },
}));
export const StyledTextField2 = styled(TextField)(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "29px",
  border:"2px solid rgba(121, 194, 216, 1)",

  "& .MuiInputLabel-root": {
    textAlign: "left",
  },

  "& .MuiInputLabel-shrink": {
    margin: "19px auto",
    position: "absolute",
    left: "0",
    top: "-4px",
    width: "150px",
    background: "none",
  },

  "& .MuiOutlinedInput-root": {
    borderRadius: "100rem",
    backgroundColor: "rgba(250, 251, 252, 1)",
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
    "& input": {
      transition: theme.transitions.create("margin-top"),
    },
    "& input:not(:placeholder-shown)": {
      marginTop: "5px", // Apply margin-top when there is text in the input
    },
  },



    "& .MuiInputBase-root": {
      backgroundColor: "rgba(250, 251, 252, 1)",
      borderRadius: "100rem",
    },

    "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-b466pw-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
      marginTop: "6px", // Add your desired margin-top value here
    },

}));
